import React, { useEffect } from 'react';

import styles from './ControlOverlay.module.scss';

export default function ControlOverlay ({controlOverlay, setControlOverlay, page}) {
    useEffect(() => {
        const sleep = ms => {
            return new Promise(resolve => setTimeout(resolve, ms));
        };
        
        const removeOverlay = async () => {
            await sleep(2000);
            setControlOverlay(false);
        };

        if (controlOverlay) {
            removeOverlay();
        }
    }, [controlOverlay, setControlOverlay]);

    return (
        <div className={`${styles[`${page}_controlOverlay`]} ${styles.controlOverlay}${!controlOverlay ? ` ${styles.controlOverlayHidden}` : ''}`}>
            <p>Use <kbd>Ctrl</kbd> + <kbd>Scroll</kbd> to zoom in or out</p>
        </div>
    );
};