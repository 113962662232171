import React from 'react';
import { Link } from 'react-router-dom';

import BreadcrumbSocial from '../common/BreadcrumbSocial';

import styles from './InvalidParcelNumber.module.scss';

export default function InvalidParcelNumber({parcelNumber}) {
    const renderErrorMessage = () => {
        if (parcelNumber) {
            return <p>The parcel number <strong>{parcelNumber}</strong> is not valid.</p>;
        } else {
            return <p>A parcel number was not provided for this request.</p>;
        }
    }

    return (
        <div className={styles.errorBody}>
            <BreadcrumbSocial pageTitle="Error" />
            <h1>Invalid Parcel Number</h1>
            {renderErrorMessage()}
            <p>Return to <Link to="/ParcelSearch">Parcel Search</Link> or <Link to="PermitSearch">Permit Record Search</Link> to try again.</p>
        </div>
    );
}