import React, { useState } from 'react';

import styles from './MultiSelectOption.module.scss';

export default function MultiSelectOption({checked, disabled, handleSelect, inputId, labelText, value}) {
    const [hasFocus, setHasFocus] = useState(false);
    
    return (
        <li className={`${styles.multiSelectOption} ${hasFocus ? styles.active : ''}`}>
            <label htmlFor={inputId}>{labelText}</label>
            <input 
                type="checkbox"
                id={inputId} 
                value={value}
                onChange={handleSelect}
                onFocus={() => setHasFocus(true)}
                onBlur={() => setHasFocus(false)}
                checked={checked}
                disabled={disabled}
            />
        </li>
    );
};