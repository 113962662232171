import React from 'react';

import Breadcrumbs from './Breadcrumbs';
import SocialShare from './SocialShare';

import useTitle from '../../hooks/useTitle';

import styles from './BreadcrumbSocial.module.scss';


export default function BreadcrumbSocial({pageTitle}) {
    useTitle(pageTitle);

    return (
        <div className={styles.breadcrumbSocial}>
            <Breadcrumbs pageTitle={pageTitle} />
            <SocialShare pageTitle={pageTitle} />
        </div>
    );
};