import React, { useEffect, useState } from 'react';

import Permits from './Permits';
import PermitTypeButtonsContainer from './filter/PermitTypeButtonsContainer';

import styles from './PermitListing.module.scss';

export default function PermitListing({displayPermit, permitData, permitsError, permitPrinting, setPermitPrinting}) {
    const [displayedType, setDisplayedType] = useState('all');
    const [selectedPermit, setSelectedPermit] = useState('');

    useEffect(() => {
        if (displayPermit) {
            handleSelectPermit(displayPermit);
        }
    }, [displayPermit]);

    const togglePermitTypeDisplay = permitType => {
        setSelectedPermit('');
        setDisplayedType(permitType.toLowerCase());
    };

    const handleSelectPermit = permitNumber => {
        setSelectedPermit(permitNumber);
        setDisplayedType('none');
    };

    return (
        <div className={`${styles.permitListing}${permitPrinting ? ` ${styles.cardPrinting}` : ''}`}>
            <div className={styles.permitFilter}>
                <h2>Permit Filtering</h2>
                <PermitTypeButtonsContainer
                    distinctPermitNumbers={permitData?.distinctPermitNumbers}
                    permitTypeCounts={permitData?.permitTypeCounts}
                    displayedType={displayedType}
                    selectedPermit={selectedPermit}
                    togglePermitTypeDisplay={togglePermitTypeDisplay}
                    handleSelectPermit={handleSelectPermit}
                />
            </div>
            <Permits 
                permits={permitData?.permits}
                displayedType={displayedType}
                selectedPermit={selectedPermit}
                permitPrinting={permitPrinting}
                setPermitPrinting={setPermitPrinting}
            />
            {permitsError?.length > 0 && 
            <p className={styles.errorBox}>{permitsError}</p>
            }
            {permitsError === null && permitData === null &&
            <p className={styles.errorBox}>Loading...</p>
            }
        </div>
    );
};