import React from 'react';

import OrSplitter from '../../../common/OrSplitter';
import PermitFilter from './PermitFilter';
import PermitTypeButton from './PermitTypeButton';

import styles from './PermitTypeButtonsContainer.module.scss';

export default function PermitTypeButtonsContainer({displayedType, distinctPermitNumbers, handleSelectPermit, permitTypeCounts, selectedPermit, togglePermitTypeDisplay}) {
    const renderAllButton = () => {
        return (
            <PermitTypeButton
                permitType="All"
                permitCount={permitTypeCounts?.All ? permitTypeCounts.All : 0}
                togglePermitTypeDisplay={togglePermitTypeDisplay}
                displayedType={displayedType}
            />
        );
    };

    const renderPermitTypeButtons = () => {
        if (permitTypeCounts) {
            return Object.keys(permitTypeCounts).reduce((accumulator, type) => {
                if (type === 'All') {
                    return accumulator;
                } else if (permitTypeCounts[type] > 0) {
                    return [...accumulator, (
                        <PermitTypeButton
                            key={type}   
                            permitType={type}
                            permitCount={permitTypeCounts[type]}
                            togglePermitTypeDisplay={togglePermitTypeDisplay}
                            displayedType={displayedType}
                        />
                    )];
                } else {
                    return accumulator;
                }
            }, []);
        }
    };
    
    return (
        <div className={styles.contentBlock}>
            <p>Display Permits by Group:</p>
            <div className={styles.allButtonContainer}>
                {renderAllButton()}
            </div>
            <div className={styles.permitTypeButtonsContainer}>
                {renderPermitTypeButtons()}
            </div>
            <OrSplitter />
            <PermitFilter
                distinctPermitNumbers={distinctPermitNumbers}
                handleSelectPermit={handleSelectPermit}
                value={selectedPermit}
            />
        </div>
    );
};