import React from 'react';

import Activity from './Activity';

import styles from './DetailedTimelineStep.module.scss';

export default function DetailedTimelineStep({permitNumber, step}) {
    const renderActivityStatuses = (group) => {
        return group.activities.map(activity => {
            return <Activity
                key={`${permitNumber}_${group.activityDescription}_${activity.activityStatus}_${activity.activityDate}`}
                activity={activity}
                activityDescription={group.activityDescription}
                permitNumber={permitNumber}
            />
        });
    };
    
    const renderActivityGroups = () => {
        return step.activityGroups.map(group => {
            return (
                <li 
                    key={`${permitNumber}_${group.activityDescription}`}
                    className={styles.activityGroup}
                >
                    <h5>{group.activityDescription}</h5>
                    <ul className={styles.activityList}>
                        {renderActivityStatuses(group)}
                    </ul>
                </li>
            )
        });
    };

    return (
        <div className={styles.timelineStep}>
            <div className={styles.stepHeader}>
                <h4>{step.step}</h4>
            </div>
            <ul className={styles.activityGroups}>
                {renderActivityGroups()}
            </ul>
        </div>
    );
};