import React from 'react';

import DetailToggleButton from './DetailToggleButton';

import styles from './DetailToggleSelector.module.scss';

export default function DetailToggleSelector({detailDisplayed, includeDocuments, includeTimeline, numDocuments, permitNumber, setDetailDisplayed}) {
    return (
        <div className={styles.detailToggle}>
            {includeTimeline &&
            <DetailToggleButton 
                toggleType='timeline'
                detailDisplayed={detailDisplayed}
                setDetailDisplayed={setDetailDisplayed}
                permitNumber={permitNumber}
            />
            }
            {includeDocuments &&
            <DetailToggleButton 
                toggleType='documents'
                detailDisplayed={detailDisplayed}
                setDetailDisplayed={setDetailDisplayed}
                numDocuments={numDocuments}
                permitNumber={permitNumber}
            />
            }
        </div>
    );
};