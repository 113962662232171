import React from 'react';
import { Link } from 'react-router-dom';

import BreadcrumbSocial from '../common/BreadcrumbSocial';

export default function PageNotFound() {
    return (
        <>
            <BreadcrumbSocial pageTitle={"Error 404: Page Not Found"}/>
            <h1>Error 404: Page cannot be found</h1>
            <div>
                <p>Visit the <Link to="/">Parcel Search and Permit Search</Link> page or the <a href="https://permitsonoma.org">Permit Sonoma</a> home page</p>
                <p>HTTP Error 404: Page not found.</p>
            </div>
        </>
    );
};