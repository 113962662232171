import React from 'react';

import { faEnvelopeSquare, faPrint } from '@fortawesome/free-solid-svg-icons';
import { faFacebookSquare, faTwitterSquare } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from './SocialShare.module.scss';

export default function SocialShare({pageTitle}) {
    return (
        <div className={styles.share}>
            <span>Share this: </span>
            <a 
                aria-label="Share on Facebook"
                href={`https://www.facebook.com/sharer/sharer.php?t=${encodeURIComponent(pageTitle)}&u=${encodeURIComponent(window.location.href)}`}
            >
                <FontAwesomeIcon title="Share on Facebook" icon={faFacebookSquare} />
            </a>
            <a 
                aria-label="Share on Twitter"
                href={`http://twitter.com/share?text=${encodeURIComponent(pageTitle)}&u=${encodeURIComponent(window.location.href)}`}
            >
                <FontAwesomeIcon title="Share on Twitter" icon={faTwitterSquare} />
            </a>
            <a 
                aria-label="Share with Email"
                href={`mailto:?body=${encodeURIComponent(window.location.href)}&Subject=${encodeURIComponent(pageTitle)}`}
            >
                <FontAwesomeIcon title="Share with Email" icon={faEnvelopeSquare} />
            </a>
            <button 
                type="button"
                onClick={() => window.print()}
                aria-label="Print this Page"
            >
                <FontAwesomeIcon title="Print this Page" icon={faPrint} />
            </button>
        </div>
    );
};