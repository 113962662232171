import { loadCss } from 'esri-loader';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';

import AddressSelectDialog from './AddressSelectDialog';
import BreadcrumbSocial from '../common/BreadcrumbSocial';
import Disclaimer from '../common/Disclaimer';
import PermitMap from '../common/Maps/PermitMap';
import PermitSearchFieldset from './PermitSearchFieldset';
import PermitSearchResults from './PermitSearchResults';

import styles from './PermitSearch.module.scss';

const mapOptions = {
    parcelLayer: {display: false},
    cityLimitsLayer: {display: true},
    page: "report"    
};

export default function PermitSearch({lastPermitSearch, setLastPermitSearch}) {
    const [searchType, setSearchType] = useState(lastPermitSearch?.searchType || 'parcelNumberSearch');
    const [parcelNumber, setParcelNumber] = useState(lastPermitSearch?.parcelNumber || '');
    const [address, setAddress] = useState(lastPermitSearch?.address || '');
    const [permitNumber, setPermitNumber] = useState(lastPermitSearch?.permitNumber || '');
    const [detailParams, setDetailParams] = useState(lastPermitSearch?.detailParams || {});
    const [searchInProgress, setSearchInProgress] = useState(false);
    const [addressCandidates, setAddressCandidates] = useState(null);
    const [addressSearched, setAddressSearched] = useState(null);
    const [message, setMessage] = useState(null);
    const [numResults, setNumResults] = useState(null);
    const [displayedParcelPermits, setDisplayedParcelPermits] = useState([]);
    const [results, setResults] = useState([]);
    const submitRef = useRef();

    useEffect(() => {
        window.scrollTo(0,0);
        loadCss();
    }, []);

    useEffect(() => {
        if (addressCandidates?.length > 0) {
            document.body.classList.add(styles.noScroll);
        } else {
            document.body.classList.remove(styles.noScroll);
        }
    }, [addressCandidates]);

    useEffect(() => {
        return () => {
            setLastPermitSearch({
                searchType,
                address,
                parcelNumber,
                permitNumber,
                detailParams
            });
        };
    }, [searchType, parcelNumber, address, permitNumber, detailParams, setLastPermitSearch]);

    const handleSearch = async () => {
        clearResults();
        setSearchInProgress(true);
        setMessage({text: 'Searching...'});
        let response;
        switch (searchType) {
            case 'parcelNumberSearch': 
                let currentParcelNum = parcelNumber;
                if (parcelNumber.match(/^\d{6,9}$/)) {
                    currentParcelNum = currentParcelNum.replace(/(\d{3})(\d{3})(\d{0,3})/, "$1-$2-$3")
                    setParcelNumber(currentParcelNum);
                }

                response = await axios.get(`/api/permits/search?parcelnumber=${encodeURIComponent(currentParcelNum)}`)
                .catch(() => {
                    setMessage({error: true, text: 'There was an error communicating with the server. Please try again later.'});
                });
            
                if (response) {
                    if (response?.data?.length) {
                        setResults(response.data);
                        processParcelPermits(response.data);
                    } else {
                        setMessage({text:'No permits matching the search criteria were found.'});
                        setSearchInProgress(false);
                    }
                }
                
                setSearchInProgress(false);
                break;
            case 'addressSearch':                          
                response = await axios.get(`/api/addresses/search?address=${encodeURIComponent(address)}`)
                .catch(() => {
                    setMessage({error: true, text: 'There was an error communicating with the server. Please try again later.'});                    
                });
                
                if (response) {
                    if (response?.data?.length) {
                        if (response.data.length === 1 && response.data[0].exact) {
                            handleSelectAddress(response.data[0].value);
                        } else if (response.data.length === 1) {
                            handleSelectAddress(response.data[0].value, false);
                        }else if (response.data.length > 1) {
                            setAddressCandidates(response.data);
                        }
                    } else {
                        setMessage({text: 'No permits matching the search criteria were found.'});
                        setSearchInProgress(false);
                    }
                } 
                break;
            case 'permitNumberSearch':
                response = await axios.get(`/api/permits/search?permitnumber=${encodeURIComponent(permitNumber)}`)
                .catch(() => {
                    setMessage({error: true, text: 'There was an error communicating with the server. Please try again later.'});
                });
                
                if (response) {
                    if (response?.data?.length) {
                        setResults(response.data);
                        processParcelPermits(response.data);
                    } else {
                        setMessage({text: 'No permits matching the search criteria were found.'});
                        setSearchInProgress(false);
                    }
                }
                
                setSearchInProgress(false);
                break;
            case 'detailSearch': 
                let formattedParams = {...detailParams};
                formattedParams.permitTypes = formattedParams.permitTypes.includes("All") ? [] : formattedParams.permitTypes;
                formattedParams.permitStatuses = formattedParams.permitStatuses.includes("All") ? [] : formattedParams.permitStatuses;
                formattedParams.permitLocations = formattedParams.permitLocations.includes("All") ? [] : formattedParams.permitLocations;
                response = await axios.post('/api/permits/search', formattedParams)
                .catch(() => {
                    setMessage({error: true, text: 'There was an error communicating with the server. Please try again later.'});
                });

                if (response) {
                    if (response?.data?.length) {
                        setResults(response.data);
                        processParcelPermits(response.data);
                    } else {
                        setMessage({text: 'No permits matching the search criteria were found'});
                        setSearchInProgress(false);
                    }
                }
                
                setSearchInProgress(false);
                break;
            default: 
                break;
        }        
    };

    const handleSelectAddress = async (address, updateAddressText = true) => {
        if (updateAddressText) {
            setAddressSearched(address);
            setAddress(address);
        } else {
            setAddressSearched(null);
        }

        setAddressCandidates(null);
        const response = await axios.get(`/api/permits/search?address=${encodeURIComponent(address)}`);
                        
        if (response.data) {
            setResults(response.data);
            processParcelPermits(response.data);
        }
        
        setSearchInProgress(false);
    };

    const processParcelPermits = parcelPermits => {
        let numPermits = parcelPermits.reduce((accumulator, currentValue) => accumulator += currentValue.permits.length, 0);
        setNumResults(numPermits);
        let numPermitsUnmappable = 0;
        let mappableParcels = [];

        for (let feature of parcelPermits) {
            if (feature?.geometry?.x && feature?.geometry?.y) {
                mappableParcels.push(feature);
            } else {
                numPermitsUnmappable += feature.permits.length;
            }
        }

        if (numPermitsUnmappable > 0) {
            if (mappableParcels.length === 0) {
                setMessage({text: `${numPermits} permit${numPermits === 1 ? ' was' : 's were'} found but could not be displayed on the map. See the full table of results below.`});
            } else {
                setMessage({text: `${numPermits} permits were found but ${numPermitsUnmappable} permit${numPermitsUnmappable === 1 ? '' : 's'} could not be displayed on the map. See the full table of results below.`});
            }
        } else {
            setMessage({text: `${numPermits} permit${numPermits === 1 ? ' was' : 's were'} found`});
        }

        setDisplayedParcelPermits(mappableParcels);
    };

    const clearResults = () => {
        setResults([]);
        setDisplayedParcelPermits([]);
        setNumResults('');
        setMessage(null);        
        setAddressSearched(null);
        setAddressCandidates(null);
        setSearchInProgress(false);
    };

    return (
        <>
            <div>   
                <BreadcrumbSocial pageTitle={"Permit Record Search"}/>
                <h1 className={styles.hasSubtitle}>Permit Record Search</h1>
                <p className={styles.subTitle}>(Formerly Permit History Search)</p>
                <div className={styles.intro}>
                    <p>This service provides convenient access to permit information, current and historic, for properties located within unincorporated Sonoma County. For help understanding what the permit numbers mean, visit our <a href="https://permitsonoma.org/x83183.xml">Permit Numbering Information</a> page.</p>
                </div>
                <div className={styles.searchContainer}>
                    <div className={styles.searchControls}>
                        <PermitSearchFieldset
                            setSearchType={setSearchType} 
                            searchType={searchType}
                            disabled={searchInProgress} 
                            address={address}
                            detailParams={detailParams}
                            handleSearch={handleSearch}
                            parcelNumber={parcelNumber}
                            permitNumber={permitNumber}
                            searchInProgress={searchInProgress}
                            setAddress={setAddress}
                            setDetailParams={setDetailParams}
                            setParcelNumber={setParcelNumber}
                            setPermitNumber={setPermitNumber}
                            submitRef={submitRef}
                        />
                    </div>
                    <div 
                        id="permitSearchMap"
                        className={styles.mapContainer}
                    >
                        <PermitMap 
                            mapOptions={mapOptions}
                            displayedParcelPermits={displayedParcelPermits}
                        />
                        {message?.text?.length > 0 &&
                        <p className={`${styles.statusMessage}${message?.error ? ' error' : ''} `}>{message.text}</p>
                        }
                    </div>
                </div>
                {results?.length > 0 && 
                <PermitSearchResults 
                    results={results} 
                    numResults={numResults}
                    address={addressSearched}
                /> 
                }
                <Disclaimer />                
            </div>
            {addressCandidates?.length > 0 &&
            <AddressSelectDialog
                handleSelectAddress={handleSelectAddress}
                addressCandidates={addressCandidates}
                originalInput={address}
                clearResults={clearResults}
                submitRef={submitRef}
            /> 
            }
        </>
    );
};