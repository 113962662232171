import { useEffect } from "react"

export default function useTitle(title) {
    const previousTitle = document.title;

    useEffect(() => {
        document.title = `${title} | Permit Sonoma`;
    }, [title]);
        
    return () => {
        document.title = previousTitle;
    };
}