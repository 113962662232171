import React from 'react';

import DetailSearch from './DetailSearch';
import PermitSearchTypeButton from './PermitSearchTypeButton';
import TextSearch from './TextSearch';

import styles from './PermitSearchFieldset.module.scss'

export default function PermitSearchFieldset({address, detailParams, handleSearch, parcelNumber, permitNumber, searchInProgress, searchType, setAddress, setDetailParams, setParcelNumber, setPermitNumber, setSearchType, submitRef}) {
    const handleSearchTypeChange = (event, newSearchType) => {
        if (event?.type === 'click' || 
          (event?.type === 'keypress' && (
          event?.which === 13 || event?.keyCode === 13 || event?.key === 'Enter' ||
          event?.which === 32 || event?.keyCode === 32 || event?.key === ' '))) {
            setSearchType(newSearchType !== searchType ? newSearchType : null);
        }
    };

    return (
        <fieldset className={styles.searchFieldset} disabled={searchInProgress}>
            <legend>Search By</legend>
            <div className={styles.searchFieldsetsInner}>
                <div>
                    <PermitSearchTypeButton 
                        active={searchType === 'parcelNumberSearch' ? true : false}
                        buttonText="Parcel Number"
                        handleSearchTypeChange={handleSearchTypeChange}
                        searchType="parcelNumberSearch"
                    />
                    <TextSearch 
                        searchTitle="Parcel Number Search"
                        searchPrompt="Enter a parcel number in the format: 000-000-000"
                        inputLabel="Parcel Number"
                        searchType="parcelNumberSearch"
                        selectedSearchType={searchType}
                        value={parcelNumber}
                        setValue={setParcelNumber}
                        handleSearch={handleSearch}
                        disabled={searchInProgress}
                    />
                </div>
                <div>
                    <PermitSearchTypeButton 
                        active={searchType === 'addressSearch' ? true : false}
                        buttonText="Address"
                        handleSearchTypeChange={handleSearchTypeChange}
                        searchType="addressSearch"
                    />
                    <TextSearch 
                        searchTitle="Address Search"
                        searchPrompt="Enter an address"
                        inputLabel="Address"
                        searchType="addressSearch"
                        selectedSearchType={searchType}
                        value={address}
                        setValue={setAddress}                    
                        handleSearch={handleSearch}
                        submitRef={submitRef}
                        disabled={searchInProgress}
                    />
                </div>
                <div>
                    <PermitSearchTypeButton 
                        active={searchType === 'permitNumberSearch' ? true : false}
                        buttonText="Permit Number"
                        handleSearchTypeChange={handleSearchTypeChange}
                        searchType="permitNumberSearch"
                    />
                    <TextSearch 
                        searchTitle="Permit Number Search"
                        searchPrompt="Enter a permit number"
                        inputLabel="Permit Number"
                        searchType="permitNumberSearch"
                        selectedSearchType={searchType}
                        value={permitNumber}
                        setValue={setPermitNumber}                    
                        handleSearch={handleSearch}
                        disabled={searchInProgress}
                    />
                </div>
                <div>
                    <PermitSearchTypeButton 
                        active={searchType === 'detailSearch' ? true : false}
                        buttonText="Permit Details"
                        handleSearchTypeChange={handleSearchTypeChange}
                        searchType="detailSearch"
                    />                
                    <DetailSearch
                        handleSearch={handleSearch}
                        values={detailParams}
                        selectedSearchType={searchType}
                        setValue={setDetailParams}
                        disabled={searchInProgress}
                    />
                </div>
            </div>
        </fieldset>            
    );
};







