import React, { useEffect, useRef } from 'react';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import OrSplitter from '../common/OrSplitter';

import useClickOutside from '../../hooks/useClickOutside';

import styles from './AddressSelectDialog.module.scss';

export default function AddressSelectDialog({addressCandidates, clearResults, handleSelectAddress, originalInput, submitRef}) {
    const [containerRef, firstElementRef] = useClickOutside();
    const lastElementRef = useRef();

    const renderAddressCandidates = () => {
        if (addressCandidates?.length > 0) {
            return addressCandidates.map(address => {
                return (
                    <p key={address.value}>
                        <button 
                            type="button" 
                            onClick={() => handleSelectAddress(address.value)}
                        >
                            {address.value}
                        </button>
                    </p>
                );
            });
        }
    };

    useEffect(() => {
        const handleEscapeCancel = event => {
            if (event.key === 'Escape') {
                clearResults();
            }
        };

        firstElementRef.current.focus();
        document.addEventListener('keydown', handleEscapeCancel);
        let submitButton = submitRef.current;

        return () => {
            submitButton.focus();
        }
    }, [firstElementRef, submitRef, clearResults]);

    return (
        <div className={styles.addressSelect}>
            <div 
                ref={containerRef}                 
                className={styles.addressDialog} 
                role="dialog" 
                aria-labelledby="addressSelectHeading" 
                aria-describedby="addressMessage" 
                aria-modal="true"
            >
                <div 
                    tabIndex="0" 
                    onFocus={() => lastElementRef.current.focus()}
                ></div>
                <div className={styles.closeButton}>
                    <button 
                        type="button"
                        onClick={clearResults}
                        ref={firstElementRef}
                        aria-label="Close Address Select"
                    >
                        <FontAwesomeIcon icon={faTimes} title="Close Address Select" />
                    </button>
                </div>
                <h2>Select an Address</h2>
                <p>Select an address from the following search results:</p>
                <div className={styles.addressCandidates}>
                    {renderAddressCandidates()}
                </div>
                <OrSplitter />
                <div className={styles.allButton}>
                    <button 
                        type="button"
                        onClick={() => handleSelectAddress(originalInput, false)}
                        ref={lastElementRef}
                    >
                        Show all results
                    </button>
                </div>
                <div 
                    tabIndex="0"
                    onFocus={() => firstElementRef.current.focus()}
                ></div>
            </div>
        </div>
    );
};