import React, { useState } from 'react';

import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from './SubNavLink.module.scss';

export default function SubNavLink({linkData, handleBlurMenu, keyPartial}) {
    const [childExpanded, setChildExpanded] = useState(false);

    const renderChildren = () => {
        return linkData.children.map((childLink, index) => {
            return (
                <SubNavLink                    
                    key={`${keyPartial}__${index}`}
                    linkData={childLink}
                    handleBlurMenu={handleBlurMenu}                    
                />
            )
        })
    };

    return (
        <li className={styles.subNavListItem}>
            <div>
                <a
                    href={linkData.link}
                    onBlur={(event) => handleBlurMenu(event)}
                    className="subNavLink"
                >
                    <span>{linkData.linkText}</span>
                </a>
                {linkData?.children?.length > 0 &&
                <button type="button" onClick={() => setChildExpanded(!childExpanded)}>
                    {childExpanded ? 
                        <FontAwesomeIcon icon={faMinus} title="Collapse" />
                    : 
                        <FontAwesomeIcon icon={faPlus} title="Expand" />
                    }
                </button>
                }
            </div>
            {linkData?.children?.length > 0 &&
            <ul className={childExpanded ? styles.mobileVisible : ''}>
                {renderChildren()}
            </ul>
            }
        </li>
    );
};