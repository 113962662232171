import React, { useState, useEffect, useRef } from 'react';

import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import DetailedPermitTimeline from './timeline/DetailedPermitTimeline';
import DetailToggleSelector from './DetailToggleSelector';
import PermitDocument from '../documents/PermitDocument';
import PermitTimeline from './timeline/PermitTimeline';

import styles from './PermitDetail.module.scss';

export default function PermitDetail({display, permitData, permitPrinting, setPermitPrinting}) {
    const [detailDisplayed, setDetailDisplayed] = useState(null);
    const [printing, setPrinting] = useState(false);
    const detailContainer = useRef();

    useEffect(() => {
        if (printing && permitPrinting) {
            window.print();
            setPrinting(false);
            setPermitPrinting(false);
        }
    }, [printing, permitPrinting, setPrinting, setPermitPrinting]);

    const renderPermitDetailRow = (label, value) => {
        if (value) {
            return (
                <div className={styles.permitInfoRow}>
                    <p className={styles.label}>{label}</p>
                    <p>{value}</p>
                </div>
            );
        }
    };

    const renderDocuments = () => {
        return permitData.documents.map(document => {
            return <PermitDocument
                key={`permit_${document.id}`}
                permitDocument={document} 
            />;
        });
    };

    const handlePermitDetailPrint = () => {
        setPrinting(true);
        setPermitPrinting(true);
    };

    return (
        <div className={`${styles.permitDetail}${display === true ? '' : ' hidden'}${printing ? ` ${ styles.printing}` : ''}`} ref={detailContainer}>
            <div className={styles.permitCardTitle}>
                <h2>{`${permitData.permitNumber} - ${permitData.permitType}`}</h2>   
                <button 
                    onClick={() => handlePermitDetailPrint()}
                    aria-label={`Print Permit ${permitData.permitNumber} Details`}
                >
                    <FontAwesomeIcon icon={faPrint} title={`Print Permit ${permitData.permitNumber} Details`} />
                </button>
            </div>
            <div className={styles.permitInfoContainer}>
                <div className={styles.permitInfo}>
                    {permitData.title ? <p className={styles.permitTitle}>{permitData.title}</p> : null}
                    {renderPermitDetailRow("Permit Number: ", permitData.permitNumber)}
                    {renderPermitDetailRow("Permit Type: ", permitData.permitType)}
                    {renderPermitDetailRow("Applicant: ", permitData.applicant)}
                    {renderPermitDetailRow("Primary Contractor: ", permitData.primaryContractor)}
                    {renderPermitDetailRow("Primary Address: ", permitData.primaryAddress)}
                    {renderPermitDetailRow("Permit Description: ", permitData.workDescription)}
                </div>
                <div className={styles.permitStatusContainer}>
                    <div className={styles.statusText}>
                        <span className={styles.label}>Status: </span>
                    </div>
                    <div className={`${styles.statusColor} ${permitData.statusColor ? ` ${permitData.statusColor}` : ''}`}>
                        <p>{permitData.permitStatus}</p>
                        {permitData.finalStatusDate ? new Date(permitData.finalStatusDate).toLocaleDateString() : permitData.fileDate ? new Date(permitData.fileDate).toLocaleDateString() : ''}
                    </div>
                </div>
            </div>
            {permitData.steps.some(step => step.finalStepDate) &&
            <PermitTimeline 
                steps={permitData.steps}
            />
            }
            <DetailToggleSelector 
                includeTimeline={permitData?.steps && permitData.steps.some(step => step?.activityGroups?.length > 0) ? true : false}
                includeDocuments={permitData?.documents?.length > 0 ? true : false}
                detailDisplayed={detailDisplayed}
                setDetailDisplayed={setDetailDisplayed}
                numDocuments={permitData?.documents ? permitData.documents.length : 0}
                permitNumber={permitData?.permitNumber}
            />
            <div>
                {permitData?.steps && permitData.steps.some(step => step?.activityGroups?.length > 0) &&
                <DetailedPermitTimeline 
                    permitData={permitData}
                    display={detailDisplayed === 'timeline' ? true : null}
                />
                }
                {permitData?.documents?.length > 0 && 
                <div 
                    id={`documents_${permitData?.permitNumber}`}
                    className={`${styles.permitDocuments} ${detailDisplayed === 'documents' ? '' : 'hidden'}`}
                >
                    {renderDocuments()}
                </div>
                }
            </div>
        </div>
    );
};