import React from 'react';

import styles from './TabPanel.module.scss';

export default function TabPanel({children, hidden, id, labelledBy}) {
    return (
        <div 
            role="tabpanel"
            aria-labelledby={labelledBy}
            id={id}
            tabIndex="0"
            className={`tabContent ${styles.tabContent}${hidden ? ' hidden' : ''}`}
        >
            {children}
        </div>
    );
};