import React from 'react';
import { Link } from 'react-router-dom';

import BreadcrumbSocial from '../common/BreadcrumbSocial';

import styles from './Home.module.scss';

export default function Home() {
    return (
        <div className={styles.home}>
            <BreadcrumbSocial pageTitle={"Permit and Parcel Search"}/>
            <h1>Permit and Parcel Search Services</h1>
            <div className={styles.split}>                
                <div className={styles.homeLinkContainer}>  
                    <Link
                        to="/PermitSearch"
                        className={styles.homeLink}
                    >
                        <h2>Permit Record Search</h2>
                        <img 
                            src="/permit-record.png" 
                            alt="Permit Record Search" 
                        />
                    </Link>                  
                </div>
                <div className={styles.homeLinkContainer}>
                    <Link
                        to="/ParcelSearch"
                        className={styles.homeLink}
                    >
                        <h2>Zoning & Land Use Search</h2>
                        <img 
                            src="/zoning-land-use.png" 
                            alt="Zoning & Land Use Search" 
                        />
                    </Link>
                </div>
            </div>
        </div>
    );
};