import React, { useEffect, useRef, useState } from 'react';

import MultiSelect from './MultiSelect';

import useDropdownVisible from '../../hooks/useDropdownVisible';

import styles from './DetailSearch.module.scss'

const possiblePermitTypes = [
    {id: 'Building', label: 'Building'},
    {id: 'WellAndSeptic', label: 'Well And Septic'},
    {id: 'Engineering', label: 'Engineering'},
    {id: 'Enforcement', label: 'Enforcement'},
    {id: 'Planning', label: 'Planning'},
    {id: 'CustomerService', label: 'Customer Service'},
    {id: 'AgComm', label: 'Ag Commission'},
    {id: 'Fire', label: 'Fire'}
];
const possiblePermitStatuses = [
    {id: 'Application', label: 'Application'},
    {id: 'Open', label: 'Open'},
    {id: 'Review', label: 'Review'},
    {id: 'Permit', label: 'Permit'},
    {id: 'Active', label: 'Active'},
    {id: 'Conditions Compliance', label: 'Conditions Compliance'},
    {id: 'Inspection', label: 'Inspection'},
    {id: 'Closed', label: 'Closed'}
];
const possiblePermitLocations = [
    {id: 'District 1', label: 'District 1'},
    {id: 'District 2', label: 'District 2'},
    {id: 'District 3', label: 'District 3'},
    {id: 'District 4', label: 'District 4'},
    {id: 'District 5', label: 'District 5'}
];

export default function DetailSearch({disabled, handleSearch, values, selectedSearchType, setValue}) {
    const [detailSearchParams, setDetailSearchParams] = useState(() => {
        let searchParams = {
            title: values?.title || '',
            toDate: values?.toDate || '',
            fromDate: values?.fromDate || '',
            permitTypes: values?.permitTypes || ['All', ...possiblePermitTypes.map(type => type.id)],
            permitStatuses: values?.permitStatuses || ['All', ...possiblePermitStatuses.map(status => status.id)],
            permitLocations: values?.permitLocations || ['All', ...possiblePermitLocations.map(location => location.id)]
        };
        
        if (!searchParams.toDate) {
            let date = new Date();
            searchParams.toDate = date.toISOString().substring(0, 10);
        }
        if (!searchParams.fromDate) {
            let date = new Date();
            date.setMonth(date.getMonth() - 1);
            searchParams.fromDate = date.toISOString().substring(0, 10);
        }
        
        return searchParams;
    });
    const [invalidFromDate, setInvalidFromDate] = useState(false);
    const [invalidToDate, setInvalidToDate] = useState(false);
    const [dateIntervalNegative, setDateIntervalNegative] = useState(false);
    const [dateIntervalTooLarge, setDateIntervalTooLarge] = useState(false);
    const [refList, visibleComponent, setVisibleComponent] = useDropdownVisible();
    const permitTypeRef = useRef();
    const permitStatusRef = useRef();
    const permitLocationRef = useRef();
    refList.push(permitTypeRef);
    refList.push(permitStatusRef);
    refList.push(permitLocationRef);    

    const setTitle = event => {        
        let tempSearchParams = {...detailSearchParams};
        tempSearchParams.title = event.target.value;
        setDetailSearchParams(tempSearchParams);
    };
    const setToDate = event => {
        setDateIntervalTooLarge(false);
        setDateIntervalNegative(false);
        setInvalidToDate(false);
        let tempSearchParams = {...detailSearchParams};
        tempSearchParams.toDate = event.target.value;
        setDetailSearchParams(tempSearchParams);
    }; 
    const setFromDate = event => {
        setDateIntervalTooLarge(false);
        setDateIntervalNegative(false);
        setInvalidFromDate(false);
        let tempSearchParams = {...detailSearchParams};
        tempSearchParams.fromDate = event.target.value;
        setDetailSearchParams(tempSearchParams);
    };
    const setSelectedPermitTypes = types => {
        let tempSearchParams = {...detailSearchParams};
        tempSearchParams.permitTypes = types;
        setDetailSearchParams(tempSearchParams);
    };
    const setSelectedPermitStatuses = statuses => {
        let tempSearchParams = {...detailSearchParams};
        tempSearchParams.permitStatuses = statuses;
        setDetailSearchParams(tempSearchParams);
    };
    const setSelectedPermitLocations = locations => {
        let tempSearchParams = {...detailSearchParams};
        tempSearchParams.permitLocations = locations;
        setDetailSearchParams(tempSearchParams);
    };

    const getDateInterval = () => {
        let dateDifference = new Date(detailSearchParams.toDate) - new Date(detailSearchParams.fromDate);
        return dateDifference / (1000 * 60 * 60 * 24);
    };

    const handleSearchSubmit = () => {
        if (!detailSearchParams.toDate || !detailSearchParams.fromDate) {
            if (!detailSearchParams.toDate) {
                setInvalidToDate(true);
            }
            if (!detailSearchParams.fromDate) {
                setInvalidFromDate(true);
            }
        } else {
            if (getDateInterval() < 0) {
                setDateIntervalNegative(true);
            } else if (getDateInterval() > 90) {
                setDateIntervalTooLarge(true);
            } else {
                handleSearch();
            }
        }
    };

    const handleEnterSubmit = event => {
        if (event.key === 'Enter') {
            handleSearchSubmit()
        }
    };

    useEffect(() => {
        setValue(detailSearchParams);
    }, [detailSearchParams, setValue]);

    return (
        <div 
            id={`detailSearch_container`}
            className={`${styles.detailSearchContainer}${selectedSearchType === 'detailSearch' ? '' : ' hidden'}`}
        >
            <fieldset>
                <legend>Permit Detail Search</legend>                       
                <div className={styles.search}>
                    <div className={styles.searchInput}>
                        <label htmlFor="titleSearch">Permit Title</label>
                        <input 
                            type="search" 
                            id="titleSearch" 
                            value={detailSearchParams.title} 
                            onChange={setTitle} 
                            onKeyPress={event => handleEnterSubmit(event)} 
                            disabled={disabled}
                        />
                    </div>
                    <div className={styles.searchInput}>
                        <label htmlFor="startDate">From Date<img src="/required_asterisk.png" alt="Required" /></label>
                        {invalidFromDate && 
                        <p className={styles.error}>Enter a starting date</p>
                        }
                        <input 
                            id="startDate"
                            runat="server" 
                            type="date" 
                            value={detailSearchParams.fromDate} 
                            onChange={setFromDate} 
                            required 
                            disabled={disabled}
                        />
                    </div>
                    <div className={styles.searchInput}>
                        <label htmlFor="endDate">To Date<img src="/required_asterisk.png" alt="Required" /></label>
                        {invalidToDate && 
                        <p className={styles.error}>Enter an ending date</p>
                        }
                        <input 
                            id="endDate"
                            runat="server" 
                            type="date" 
                            value={detailSearchParams.toDate} 
                            onChange={setToDate} 
                            required 
                            disabled={disabled}
                        />
                    </div>
                </div>
                <div>
                    <MultiSelect 
                        searchTitle="Permit Type"
                        selectTypes="Permit Type"
                        id="permitType"
                        options={possiblePermitTypes}
                        selectedOptions={detailSearchParams.permitTypes}
                        setSelectedOptions={setSelectedPermitTypes}
                        refs={permitTypeRef}
                        visible={visibleComponent}
                        setVisibleComponent={setVisibleComponent}
                        disabled={disabled}
                    />
                    <MultiSelect 
                        searchTitle="Permit Status"
                        selectTypes="Permit Statuses"
                        id="permitStatus"
                        options={possiblePermitStatuses}
                        selectedOptions={detailSearchParams.permitStatuses}
                        setSelectedOptions={setSelectedPermitStatuses}
                        refs={permitStatusRef}
                        visible={visibleComponent}
                        setVisibleComponent={setVisibleComponent}
                        disabled={disabled}
                    />
                    <MultiSelect 
                        searchTitle="Permit Location"
                        selectTypes="Permit Locations"
                        id="permitLocation"
                        options={possiblePermitLocations}
                        selectedOptions={detailSearchParams.permitLocations}
                        setSelectedOptions={setSelectedPermitLocations}
                        refs={permitLocationRef}
                        visible={visibleComponent}
                        setVisibleComponent={setVisibleComponent}
                        disabled={disabled}
                    />
                </div>                
                <div className={styles.searchButtonContainer}>
                    <button                         
                        type="button" 
                        className={styles.searchButton}
                        onClick={handleSearchSubmit}
                    >
                        Search
                    </button>
                </div>
                {dateIntervalNegative && 
                    <p className={styles.error}>From Date must be earlier than To Date</p>
                }
                {dateIntervalTooLarge && 
                    <p className={styles.error}>Date interval must be 90 days or less</p>
                }
            </fieldset>
        </div>
    );
};