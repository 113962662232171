import React from 'react';

import MultiSelectOption from './MultiSelectOption';

import styles from './MultiSelect.module.scss'

export default function MultiSelect({disabled, id, options, refs, searchTitle, selectedOptions, selectTypes, setSelectedOptions, setVisibleComponent, visible}) {
    const toggleVisible = () => {
        setVisibleComponent(refs)
    };

    const handleSelect = event => {
        if (event.target.value === 'All') {
            if (selectedOptions.includes('All')) {
                setSelectedOptions([]);
            } else {
                let newSelectedOptions = options.map(option => option.id);
                newSelectedOptions = ['All', ...newSelectedOptions];
                setSelectedOptions(newSelectedOptions);
            }
        } else {
            if (selectedOptions.includes(event.target.value)) {
                setSelectedOptions(selectedOptions.filter(option => {
                    return option !== event.target.value && option !== 'All';
                }));
            } else {
                let newOptions = [...selectedOptions, event.target.value]
                if (newOptions.length === options.length) {
                    setSelectedOptions(['All', ...newOptions]);
                } else {
                    setSelectedOptions(newOptions);
                }
            }
        }
    };

    const renderOptions = () => {
        if (options?.length > 0) {
            let allOption = (
                <MultiSelectOption
                    key={`${id}_all`}     
                    inputId={`${id}_all`} 
                    handleSelect={handleSelect}
                    checked={selectedOptions.includes('All')}
                    disabled={disabled}
                    labelText="Select All"
                    value="All"
                />
            );        

            let multiSelectOptions = options.map(option => {
                return (
                    <MultiSelectOption
                        key={`${id}_${option.id}`}
                        inputId={option.id} 
                        handleSelect={handleSelect}
                        checked={selectedOptions.includes(option.id)}
                        disabled={disabled}
                        labelText={option.label}
                        value={option.id}
                    />
                )
            });

            return [allOption, ...multiSelectOptions]
        }
    };

    return (
        <div className={styles.multiSelect}>
            <legend className={styles.searchTitle}>{searchTitle}<img src="/required_asterisk.png" alt="Required" /></legend>
            {!selectedOptions || selectedOptions.length === 0 ? 
            <p className="error">Select one or more {selectTypes}</p> 
            : null}
            <div ref={refs}>
                <button 
                    type="button"
                    className={styles.dropdownToggle}
                    aria-expanded={visible === refs}
                    aria-controls={`${id}_options`}
                    onClick={toggleVisible}
                    disabled={disabled}
                >
                    <span className={styles.buttonText}>{selectedOptions.length === options.length + 1 ? `All Selected` : `Selected (${selectedOptions.length})`}</span>
                    {visible === refs ? <span>&#9660;</span> : <span>&#9654;</span>}
                </button>
                <div 
                    id={`${id}_options`}
                    className={`${styles.dropdown}${visible === refs ? '' : ' hidden'}`}
                >
                    <ul className={styles.options}>
                        {renderOptions()}            
                    </ul>
                </div>
            </div>
        </div>
    );
};