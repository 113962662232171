import React from 'react';

import styles from './TabSelectButton.module.scss';

export default function TabSelectButton({ariaControls, buttonText, category, currentTab, handleTabSelect}) {
    return (
        <button
            id={`${category}-btn`}
            role="tab"
            aria-selected={currentTab === category ? "true" : "false"}
            aria-controls={ariaControls}
            className={`${styles.tabSelectButton}${currentTab === category ? ` ${styles.active}` : ''}`}
            onClick={category => handleTabSelect(category)}
            onKeyPress={category => handleTabSelect(category)}
        >{buttonText}</button>
    );
};