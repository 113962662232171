import React, { useState, useRef, useEffect } from 'react';
import { render } from 'react-dom';
import { setDefaultOptions, loadModules, loadCss } from 'esri-loader';

import PermitPopup from './PermitPopup';
import ControlOverlay from './ControlOverlay';

import styles from './Map.module.scss';

const parcelLayerUrl = "https://services1.arcgis.com/P5Mv5GY5S66M8Z1Q/ArcGIS/rest/services/Parcel_Countywide/FeatureServer/0";
const cityLimitsLayerUrl = "https://services1.arcgis.com/P5Mv5GY5S66M8Z1Q/ArcGIS/rest/services/City_Limits_PSGIS/FeatureServer/0";

let modules = null;
let selectedParcelsLayer = null;
let parcelLayer = null;
let permitsLayer = null;
let cityLimitsLayer = null;
let legend = null;
let permitMap = null;
let view = null;
let home = null;

export default function PermitMap({mapOptions, displayedParcelPermits}) {
    const [controlOverlay, setControlOverlay] = useState(false);
    const mapRef = useRef();
    
    useEffect(() => {    
        const initializeMap = async () => {
            if (!modules) {
                await loadGISModules();
            }

            selectedParcelsLayer = new modules.GraphicsLayer({
                title: "GraphicsLayer",
                minScale: 50000,
                listMode: "hide"
            });

            let layers = [selectedParcelsLayer];

            parcelLayer = new modules.FeatureLayer({
                url: parcelLayerUrl,
                outFields: ["*"],
                opacity: 0.5,
                minScale: 10000,
                renderer: new modules.SimpleRenderer({
                    symbol: new modules.SimpleFillSymbol({
                        color: [140, 150, 150, .75],
                        style: "solid",
                        outline: {
                            color: "black",
                            width: 1
                        }
                    })
                }),
                definitionExpression: ["APN LIKE '%%'"],
                listMode: "hide"
            });

            if (mapOptions.parcelLayer.display) {
                layers.push(parcelLayer);
            }

            cityLimitsLayer = new modules.FeatureLayer({
                url: cityLimitsLayerUrl,
                outFields: [],
                opacity: 0.5,
                minScale: 10000000,
                renderer: new modules.SimpleRenderer({
                    symbol: new modules.SimpleFillSymbol({
                        color: [0, 0, 0, 0.2],
                        style: "solid",
                        outline: {
                            width: 0
                        }
                    })
                })
            });

            if (mapOptions.cityLimitsLayer.display) {
                layers.push(cityLimitsLayer);
            }

            permitMap = new modules.Map({
                basemap: "topo",
                layers
            });

            view = new modules.MapView({
                container: mapRef.current,
                map: permitMap,
                zoom: 16,
                center: new modules.Point({
                    "longitude": -122.713731,
                    "latitude": 38.431896
                }),
                popup: new modules.Popup({
                    dockEnabled: false,
                    dockOptions: {
                        buttonEnabled: false
                    },
                    collapseEnabled: true,
                    autoCloseEnabled: true
                })
            });

            home = new modules.Home({
                view: view
            });
        
            view.ui.add(home, "top-left");

            view.on('mouse-wheel', event => {            
                if (!event?.native?.ctrlKey) {
                    event.preventDefault();
                    event.stopPropagation();
                    setControlOverlay(true);
                }
            });
        }

        const loadGISModules = async () => {
            setDefaultOptions({ version: 4.16 });
            const [
                Map,
                MapView,
                Point,
                FeatureLayer,
                SimpleFillSymbol,
                SimpleMarkerSymbol,
                GraphicsLayer,
                Home,
                SimpleRenderer,
                UniqueValueRenderer,
                Popup,
                PopupTemplate,
                Legend
            ] = await loadModules([
                'esri/Map',
                'esri/views/MapView',
                'esri/geometry/Point',
                'esri/layers/FeatureLayer',
                'esri/symbols/SimpleFillSymbol',
                'esri/symbols/SimpleMarkerSymbol',
                'esri/layers/GraphicsLayer',
                'esri/widgets/Home',
                'esri/renderers/SimpleRenderer',
                'esri/renderers/UniqueValueRenderer',
                'esri/widgets/Popup',
                'esri/PopupTemplate',
                'esri/widgets/Legend',
            ]);

            modules = {
                Map,
                MapView,
                Point,
                FeatureLayer,
                SimpleFillSymbol,
                SimpleMarkerSymbol,
                GraphicsLayer,
                Home,
                SimpleRenderer,
                UniqueValueRenderer,
                Popup,
                PopupTemplate,
                Legend
            };   
        };

        loadCss();
        initializeMap();
    }, [mapOptions]);

    useEffect(() => {
        const generateParcelPermitLayer = async () => {
            let parcelPermitFeatures = await generateParcelPermitsPopup(displayedParcelPermits);

            const permitSymbolOptions = {
                style: "circle",
                size: "15px",            
                outline: {
                    color: [255, 255, 255],
                    width: 2
                }
            }
            const notStartedSymbol = {...permitSymbolOptions, color: [63, 122, 240]};
            const inProcessSymbol = {...permitSymbolOptions, color: [226, 119, 40]};
            const closedSymbol = {...permitSymbolOptions, color: [47, 219, 87]};
    
            permitsLayer = new modules.FeatureLayer({
                title: 'permits',
                fields: [
                    { name: 'ObjectID', alias: 'ObjectID', type: 'oid' },
                    { name: 'APN', alias: 'APN', type: 'string' },
                    { name: 'ParcelStep', alias: 'ParcelStep', type: 'small-integer' },
                    { name: 'HTML', alias: 'HTML', type: 'string' }
                ],
                objectIdField: 'ObjectID',                
                geometryType: "point",
                spatialReference: { wkid: 102100 },
                source: parcelPermitFeatures,
                popupTemplate: new modules.PopupTemplate({
                    title: "Parcel Number: {APN}",
                    outFields: ["*"],
                    content: context => {
                        let div = document.createElement("div");
                        div.innerHTML = context.graphic.attributes.HTML;
                        return div;
                    },
                    overwriteActions: true
                }),
                renderer: new modules.UniqueValueRenderer({
                    valueExpression: "When($feature.ParcelStep == 1, 'Not Started', $feature.ParcelStep == 3, 'Closed', 'In Process')",
                    uniqueValueInfos: [{
                        value: "Not Started",
                        symbol: new modules.SimpleMarkerSymbol(notStartedSymbol)
                    }, {
                        value: "In Process",
                        symbol: new modules.SimpleMarkerSymbol(inProcessSymbol)
                    }, {
                        value: "Closed",
                        symbol: new modules.SimpleMarkerSymbol (closedSymbol)
                    }]
                }),
                outFields: ["APN"]
            });
    
            permitMap.add(permitsLayer);
    
            legend = new modules.Legend({
                view: view,
                layerInfos: [{
                    layer: permitsLayer,
                    title: "Legend"
                }]
            });
    
            view.ui.add(legend, 'bottom-right');
    
            if (displayedParcelPermits.length === 1) {
                renderParcelOutline(displayedParcelPermits[0].attributes.apn);
            }
    
            if (displayedParcelPermits.length > 1) {
                view.whenLayerView(permitsLayer).then(layerView => {
                    let layerViewWatch = layerView.watch("updating", isUpdating => {
                        if (!isUpdating) {
                            view.goTo(permitsLayer.fullExtent.expand(1.05));
                            layerViewWatch.remove();
                        }
                    });
                });
            }
        };

        const generateParcelPermitsPopup = async parcelPermits => {
            for (let parcel of parcelPermits) {
                const div = document.createElement("div");

                render(<PermitPopup parcel={parcel} />, div, () => {
                    parcel.attributes.HTML = div.innerHTML;
                });    
            }
    
            return parcelPermits;
        };
    
        const renderParcelOutline = async parcelNumber => {
            const query = parcelLayer.createQuery();
            query.outfields = "APN,OBJECTID";
            query.where = `APN = '${parcelNumber}'`;
        
            const queryResponse = await parcelLayer.queryFeatures(query);
        
            if (queryResponse?.features && queryResponse.features.length > 0) {
                selectedParcelsLayer.graphics.add({
                    geometry: queryResponse.features[0].geometry,
                    symbol: new modules.SimpleFillSymbol({
                        color: [100, 135, 175, 0.35],
                        style: "solid",
                        outline: {
                            color: [75, 100, 150],
                            width: 2
                        }
                    })
                });
        
                view.whenLayerView(selectedParcelsLayer).then(layerView => {
                    layerView.queryGraphics().then(response => {
                        view.goTo(response);
                    });
                });
            }
        };

        const clearResults = () => {
            if (permitsLayer) {
                permitMap.layers.remove(permitsLayer);
            }
            if (legend) {
                view.ui.remove(legend);
            }
            if (selectedParcelsLayer?.graphics) {
                selectedParcelsLayer.graphics.removeAll();
            }
        };

        if (displayedParcelPermits?.length > 0) {
            generateParcelPermitLayer();
        } else {
            clearResults();
        }
    }, [displayedParcelPermits]);

    return (
        <div>
            <ControlOverlay
                controlOverlay={controlOverlay}
                setControlOverlay={setControlOverlay}
                page="permit"
            />
            <div className={styles.map} ref={mapRef}></div>
        </div>
    );
};