import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import axios from 'axios';
import React, { useEffect, useState } from 'react';

import BreadcrumbSocial from '../common/BreadcrumbSocial';
import Disclaimer from '../common/Disclaimer';
import DocumentListing from './documents/DocumentListing';
import InvalidParcelNumber from '../Error/InvalidParcelNumber';
import ParcelMap from '../common/Maps/ParcelMap';
import ParcelDetail from './report/ParcelDetail';
import ParcelReportTabButtons from './ParcelReportTabButtons';
import PermitListing from './permits/PermitListing';
import PermitPrintTable from './permits/PermitPrintTable';
import ResourcesSidebar from './ResourcesSidebar';
import TabPanel from './TabPanel';

import styles from './ParcelReport.module.scss';

const mapOptions = {
    parcelLayer: {display: false},
    cityLimitsLayer: {display: true},
    enableFeatureAddOnClick: false,        
    page: "report"
};

export default function ParcelReport() {
    const location = useLocation();
    const [parcelNumber, setParcelNumber] = useState();
    const [referringPage, setReferringPage] = useState();
    const [displayPermit, setDisplayPermit] = useState();
    const [addresses, setAddresses] = useState(null);
    const [parcelReportData, setParcelReportData] = useState(null);
    const [permitData, setPermitData] = useState(null);
    const [currentTab, setCurrentTab] = useState('parcel');
    const [addressesError, setAddressesError] = useState(null);
    const [parcelReportError, setParcelReportError] = useState(null);
    const [permitsError, setPermitsError] = useState(null);
    const [documentsError, setDocumentsError] = useState(null);
    const [permitPrinting, setPermitPrinting] = useState(false);    

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (location?.search) {
            const params = new URLSearchParams(location.search);
            setParcelNumber(params.get('APN') || null);
            setReferringPage(params.get('RP') || null);            
            setDisplayPermit(params.get('PN') || null);
            if (params.get('PN')) {
                setCurrentTab('permits');
            }
        }
    }, [location]);

    useEffect(() => {
        const fetchAddresses = async () => {
            if (parcelNumber) {
                let error;
                
                const response = await axios.get(`/api/parcels/search?parcelNumber=${encodeURIComponent(parcelNumber)}`)
                .catch(() => {
                    error = 'Could not load associated addresses. Please try again later';
                });
                            
                if (response?.data?.length > 0 && response.data[0]?.addresses?.length > 0) {
                    setAddresses(response.data[0].addresses);
                } else {
                    error = error ? error : 'No addresses on record';
                }
    
                if (error) {
                    setAddressesError(error);
                }
            }
        };
    
        const fetchParcelReport = async () => {
            if (parcelNumber) {
                let error;
    
                const response = await axios.get(`/api/parcelreport/${encodeURIComponent(parcelNumber)}`)
                .catch(() => {
                    error = 'Could not load parcel report data. Please try again later';
                });
                
                if (response?.data) {                
                    setParcelReportData(response.data);
                } else {
                    error = error ? error : 'No parcel report on record';
                }
    
                if (error) {
                    setParcelReportError(error);
                }
            }
        };
    
        const fetchPermitData = async () => {
            if (parcelNumber) {
                let errorPermits;
                let errorDocuments;
                
                const response = await axios.get(`/api/permits/details/${encodeURIComponent(parcelNumber)}`)
                .catch(() => {
                    errorPermits = 'Permit details were unable to be retrieved for this parcel';
                    errorDocuments = 'Permit documents were unable to be retrieved for this parcel';
                });
                
                if (response?.data) {
                    setPermitData(response.data);
    
                    if (response.data?.permits?.length > 0) {
                        if (response.data.error) {
                            errorDocuments = response.data.error;
                        } else if (!response.data.permits.some(permit => permit?.documents?.length > 0)) {
                            errorDocuments = 'No documents on record';
                        }
                    } else {
                        errorPermits = 'No permits on record';

                        if (response.data.error) {
                            errorDocuments = response.data.error;    
                        } else {
                            errorDocuments = 'No documents on record';
                        }
                    }
                }
    
                if (errorPermits) {
                    setPermitsError(errorPermits);
                }
                if (errorDocuments) {
                    setDocumentsError(errorDocuments);
                }
            }
        };

        fetchAddresses();
        fetchParcelReport();
        fetchPermitData();
    }, [parcelNumber]);

    return (
        <>
            {parcelNumber && parcelNumber.match(/\d{3}-\d{3}-\d{3}/) !== null ?
            <div className={styles.parcelReport}>            
                <BreadcrumbSocial pageTitle={"Zoning & Parcel Report"}/>
                <div className={styles.primaryHeadingBlock}>
                    <h1>Zoning &amp; Parcel Report</h1>
                    <p><span className={styles.label}>Parcel Number:</span> {parcelNumber}</p>
                </div>
                <ParcelMap 
                    mapOptions={mapOptions}
                    parcelNumber={parcelNumber}
                    permitPrinting={permitPrinting}
                />
                <p className={styles.noPrint}>
                    <Link 
                        to={referringPage && referringPage === 'permitSearch' ? '/PermitSearch' : '/ParcelSearch'}
                        className={styles.backToSearch}
                    >
                        « Return to {referringPage === 'permitSearch' ? 'Permit' : 'Parcel'} Search
                    </Link>
                </p>
                <ParcelReportTabButtons 
                    currentTab={currentTab}
                    setCurrentTab={setCurrentTab}
                />
                <div className={styles.splitBlock}>
                    <TabPanel 
                        labelledBy="parcel-btn"
                        id="parcel-tab"
                        hidden={currentTab === 'parcel' ? false : true}                    
                    >
                        <ParcelDetail
                            addresses={addresses}
                            parcelReportData={parcelReportData}
                            addressesError={addressesError}
                            parcelReportError={parcelReportError}
                        />
                    </TabPanel>
                    <TabPanel
                        labelledBy="permits-btn"
                        id="permits-tab"
                        hidden={currentTab === 'permits' ? false : true}                    
                    >
                        <PermitListing 
                            permitData={permitData}
                            permitsError={permitsError}
                            displayPermit={displayPermit}   
                            permitPrinting={permitPrinting}
                            setPermitPrinting={setPermitPrinting}
                        />
                        <PermitPrintTable
                            permits={permitData?.permits}
                            permitPrinting={permitPrinting}
                        />
                    </TabPanel>
                    <TabPanel
                        labelledBy="documents-btn"
                        id="document-tab"
                        hidden={currentTab === 'documents' ? false : true}                    
                    >
                        <DocumentListing
                            permits={permitData?.permits || null}
                            documentsError={documentsError}
                        />
                    </TabPanel>
                    <ResourcesSidebar />
                </div>
                <Disclaimer />
            </div> 
            : 
            <InvalidParcelNumber 
                parcelNumber={parcelNumber}
            />
            }
        </>
    );
};