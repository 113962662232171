import React, { useEffect, useState } from 'react';

import PermitResultsPaging from '../../common/PermitResultPaging';
import PermitDetail from './PermitDetail';

import styles from './Permits.module.scss';

export default function Permits({displayedType, permits, selectedPermit, permitPrinting, setPermitPrinting}) {
    const [filteredPermits, setFilteredPermits] = useState();
    const [displayedPermits, setDisplayedPermits] = useState();
    const [currentPage, setCurrentPage] = useState();
    const [resultsPerPage, setResultsPerPage] = useState(10);

    useEffect(() => {
        if (permits?.length > 0) {
            const permitsOfType = permits.reduce((accumulator, permit) => {
                if ((displayedType === 'none' && permit.permitNumber.toLowerCase().includes(selectedPermit.toLowerCase()))
                || (displayedType === 'all' || displayedType === permit.permitGroup.toLowerCase())) {
                    accumulator.push(permit);
                }
    
                return accumulator;
            }, []);
            
            setFilteredPermits(permitsOfType);
            setCurrentPage(1);
        }
    }, [permits, displayedType, selectedPermit, setCurrentPage, setFilteredPermits]);

    useEffect(() => {
        setCurrentPage(1);
    }, [resultsPerPage, filteredPermits, setCurrentPage]);

    useEffect(() => {
        if (filteredPermits?.length > 0) {
            if (resultsPerPage !== "all") {
                setDisplayedPermits(filteredPermits.slice((currentPage - 1) * resultsPerPage, currentPage * resultsPerPage));
            } else {
                setDisplayedPermits(filteredPermits);
            }
        }
    }, [currentPage, resultsPerPage, filteredPermits]);

    const renderPermits = () => {
        if (displayedPermits?.length > 0) {
            return displayedPermits.map(permit => {
                return (
                    <PermitDetail
                        key={permit.permitNumber}
                        permitData={permit}
                        display={true}
                        permitPrinting={permitPrinting}
                        setPermitPrinting={setPermitPrinting}
                    />
                );
            });
        }
    }; 

    return (
        <div className={styles.permits}>
            {filteredPermits?.length > 10 && 
            <>
            {filteredPermits?.length > resultsPerPage &&
            <p className={styles.permitsDisplayed}>Showing {(currentPage - 1) * resultsPerPage + 1} - {currentPage === (Math.ceil(filteredPermits.length / resultsPerPage)) ? filteredPermits.length : currentPage * resultsPerPage} of {filteredPermits.length}</p>           
            }
            <PermitResultsPaging
                totalPermits={filteredPermits?.length}
                currentPage={currentPage}
                resultsPerPage={resultsPerPage}
                setCurrentPage={setCurrentPage}
                page="parcelReport"
                setResultsPerPage={setResultsPerPage}
                resultsPerPageOptions={[10, 25, 100]}
            />
            </>
            }
            {renderPermits()}   
            {filteredPermits?.length > 10 && 
            <PermitResultsPaging
                totalPermits={filteredPermits?.length}
                currentPage={currentPage}
                resultsPerPage={resultsPerPage}
                setCurrentPage={setCurrentPage}
                page="parcelReport"
                setResultsPerPage={setResultsPerPage}
                resultsPerPageOptions={[10, 25, 100]}
            />    
            }
        </div>
    );
};