import React, { useState } from 'react';

import { faBars, faTimes, faSearch, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from './Header.module.scss';
import TopNavLink from './TopNavLink';

export default function Header({headerLinks}) {
    const [mobileMenuVisible, setMobileMenuVisible] = useState(false);
    const [mobileSearchVisible, setMobileSearchVisible] = useState(false);
    const [searchInput, setSearchInput] = useState('');    
    const [visibleMenu, setVisibleMenu] = useState();

    const handleToggleMenu = (menu) => {
        if (menu === 'menu') {
            setMobileMenuVisible(!mobileMenuVisible);
            setMobileSearchVisible(false);
        } else {
            setMobileSearchVisible(!mobileSearchVisible);
            setMobileMenuVisible(false);
        }
    };

    const handleSearchInput = event => {
        if (event.key === "Enter" || event.keyCode === 13) {
            submitSearch();
        }
    };

    const submitSearch = () => {
        if (searchInput) {
            window.location.href = `https://permitsonoma.org/x107874.xml?terms=${encodeURIComponent(searchInput)}`;
        }
    };

    const handleFocusMenu = index => {
        setVisibleMenu(index);
    };

    const handleBlurMenu = (event) => {
        if (!event?.relatedTarget?.classList?.contains("topLevelNav") && !event?.relatedTarget?.classList?.contains("subNavLink")) {
            setVisibleMenu(null);
        }     
    };

    const handleMainNavKeypress = (event, index) => {
        const currentMainNav = event.target.closest(".topLevelNav");
        const links = currentMainNav.querySelectorAll("a");
        const currentFocusedLink = Array.prototype.indexOf.call(links, event.target);

        switch (event.key) {
            case 'ArrowLeft':
                if (currentMainNav?.previousSibling?.firstChild?.firstChild) { 
                    currentMainNav?.previousSibling?.firstChild.firstChild.focus();
                } else {
                    currentMainNav?.parentNode?.lastChild?.firstChild?.firstChild?.focus();
                }
                break;
            case 'ArrowRight':
                if (currentMainNav?.nextSibling?.firstChild?.firstChild) { 
                    currentMainNav.nextSibling.firstChild.firstChild.focus();
                } else {
                    currentMainNav?.parentNode?.firstChild?.firstChild?.firstChild?.focus();
                }
                break;
            case 'ArrowDown':
                event.preventDefault();
                if (event.target.classList.contains("topNavLink")) {
                    if (visibleMenu !== index) {
                        setVisibleMenu(index);
                        setTimeout(() => {
                            links?.[1]?.focus();
                        }, 1);
                    } else {
                        links?.[1]?.focus();
                    }
                } else {
                    if (links?.[currentFocusedLink + 1]) {
                        links[currentFocusedLink + 1].focus();
                    } else {
                        links?.[0]?.focus();
                    }
                }
                break;
            case 'ArrowUp':                
                event.preventDefault();
                if (event.target.classList.contains("topNavLink")) {
                    if (visibleMenu !== index) {
                        setVisibleMenu(index);
                        setTimeout(() => {
                            links?.[links?.length - 1]?.focus();
                        }, 1);
                    } else {
                        links?.[links?.length - 1]?.focus();
                    }
                } else {
                    if (links?.[currentFocusedLink - 1]) {
                        links[currentFocusedLink - 1].focus();
                    } else {
                        links?.[links?.length - 1]?.focus();
                    }
                }
                break;
            case 'Home':
                event.preventDefault();
                if (event.target.classList.contains("topNavLink")) {
                    currentMainNav?.parentNode?.firstChild?.firstChild?.firstChild?.focus();
                } else {
                    links?.[1].focus();
                }
                break;
            case 'End':
                event.preventDefault();
                if (event.target.classList.contains("topNavLink")) {
                    currentMainNav?.parentNode?.lastChild?.firstChild?.firstChild?.focus();
                } else {
                    links?.[links?.length - 1]?.focus();
                }
                break;
            case 'Escape':
                if (!event.target.classList.contains("topNavLink")) {
                    links[0].focus();
                } 
                setVisibleMenu(null);
                break;
            default: break;
        }
    };

    const renderTopNavLinks = () => {
        return headerLinks?.map((topLevelLink, index) => {
            return (
                <TopNavLink
                    key={`topLevelNav__${index}`}
                    linkData={topLevelLink}
                    index={index}
                    subMenuVisible={visibleMenu === index}
                    handleBlurMenu={handleBlurMenu}
                    handleFocusMenu={handleFocusMenu}
                    handleMainNavKeypress={handleMainNavKeypress}
                />
            );
        });
    };

    return (
        <header>
            <img className={styles.printOnly} src="PRMD-New-Logo.png" alt="Permit Sonoma" />
            <div>
                <div className={styles.navContainer}>
                    <nav>
                        <div className={styles.logo}>                        
                            <a href="https://sonomacounty.ca.gov">
                                <FontAwesomeIcon className={styles.returnArrow} icon={faArrowLeft} title='County of Sonoma' /> County of Sonoma
                            </a>
                        </div>
                    </nav>
                </div>
                <div className={styles.banner}>
                    <div className={styles.bannerMain}>
                        <div className={`${styles.container} ${styles.innerBanner}`}>
                            <div className={styles.pageTitle}>
                                <button 
                                    type="button"
                                    onClick={() => handleToggleMenu('menu')} 
                                    className={styles.mobileMenuBtn} 
                                    aria-label={mobileMenuVisible ? 'Close Menu' : 'Open Menu'}
                                >
                                    {
                                        mobileMenuVisible ? 
                                        <FontAwesomeIcon icon={faTimes} title="Close Menu" />
                                        :
                                        <FontAwesomeIcon icon={faBars} title="Open Menu" />
                                    }
                                </button>
                                <a className={styles.logoLink} href="https://permitsonoma.org">           
                                    <img src="permit-sonoma-logo-116.png" alt="Permit Sonoma Logo" />                     
                                    <div>
                                        <span className={styles.countyTitle}>Sonoma County</span><br />
                                        <span className={styles.departmentTitle}>Permit Sonoma</span>
                                    </div>
                                </a>
                                <button 
                                    type="button" 
                                    onClick={() => handleToggleMenu('search')} 
                                    className={styles.mobileSearchBtn} 
                                    aria-label={mobileSearchVisible ? 'Close Search' : 'Open Search'}
                                >
                                    {
                                        mobileSearchVisible ? 
                                        <FontAwesomeIcon icon={faTimes} title="Close Search" />
                                        :
                                        <FontAwesomeIcon icon={faSearch} title="Open Search" />
                                    }
                                </button>
                            </div>
                            <div className={`${styles.siteSearch} ${mobileSearchVisible ? '' : styles.mobileHidden}`}>
                                <label htmlFor="searchInput">Search</label>
                                <input 
                                    type="search"
                                    id="searchInput" 
                                    name="searchInput" 
                                    onChange={event => setSearchInput(event.target.value)}
                                    value={searchInput}
                                    onKeyDown={event => handleSearchInput(event)}
                                    placeholder="Search …" 
                                />
                                <button 
                                    type="button" 
                                    aria-label="Submit Search" 
                                    onClick={() => submitSearch()}
                                >
                                    <FontAwesomeIcon icon={faSearch} title="Submit Search" />
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className={`${styles.bannerSub} ${mobileMenuVisible ? '' : styles.mobileHidden}`}>
                        <div className={styles.container}>
                            <ul className={`${styles.subNav} ${styles.links}`}>
                                {renderTopNavLinks()}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <img 
                className={styles.printOnly} 
                src="Sonoma-County-Logo-300.png" 
                alt="County of Sonoma" 
            />
        </header>
    );
};