import axios from 'axios';
import React, { useEffect, useState } from 'react';

import BreadcrumbSocial from '../common/BreadcrumbSocial';
import Disclaimer from '../common/Disclaimer';
import ParcelMap from '../common/Maps/ParcelMap';
import ParcelSearchResults from './ParcelSearchResults';

import styles from './ParcelSearch.module.scss';

const mapOptions = {
    parcelLayer: {display: true},
    cityLimitsLayer: {display: true},
    enableFeatureAddOnClick: true,    
    page: "parcel"
};

export default function ParcelSearch({lastParcelSearch, setLastParcelSearch}) {
    const [message, setMessage] = useState(null);
    const [address, setAddress] = useState(lastParcelSearch?.address || '');
    const [parcelNumber, setParcelNumber] = useState(lastParcelSearch?.parcelNumber || '');
    const [selectedParcels, setSelectedParcels] = useState([]);
    const [searchInProgress, setSearchInProgress] = useState(false);
    const [sortDirection, setSortDirection] = useState(null);
    const [sortField, setSortField] = useState(null);

    useEffect(() => {
        window.scrollTo(0,0);
    }, []);

    useEffect(() => {
        return () => {
            setLastParcelSearch({
                address,
                parcelNumber,
            });
        };
    }, [address, parcelNumber, setLastParcelSearch]);

    const queryParcelClick = async parcelNumber => {        
        setMessage(null);
        setSortDirection(null);
        setSortField(null);

        setSelectedParcels(previousParcels => {
            return [...previousParcels, {
                parcelNumber,
                addresses: ['Loading...']
            }];
        });

        const response = await axios.get(`/api/parcels/search?parcelNumber=${encodeURIComponent(parcelNumber)}`);

        if (response.data) {
            let addresses = null;
            if (response.data?.[0]?.addresses) {
                addresses = response.data[0].addresses;    
            } else {
                addresses = [];
            }

            setSelectedParcels(previousParcels => {
                let found = false;

                let newSelectedParcels = previousParcels.map(parcel => {
                    if (parcel.parcelNumber === parcelNumber) {
                        parcel.addresses = addresses;
                        found = true;
                    } 
                    return parcel;
                });
    
                if (!found) {
                    newSelectedParcels.push({
                        parcelNumber, 
                        addresses: addresses
                    });
                }

                return newSelectedParcels;
            });
        }
    };

    const removeParcel = parcelNumber => {
        setSelectedParcels(selectedParcels.filter(value => value.parcelNumber !== parcelNumber));
    };

    const handleParcelNumberSearch = async () => {
        clearSelectedParcels();
        setSearchInProgress(true);
     
        if (parcelNumber.match(/^(\d{6,9}|\d{3}-\d{3}(-\d{0,3}){0,1})$/)) {
            let currentParcelNum = parcelNumber;
            if (parcelNumber.match(/^\d{6,9}$/)) {
                currentParcelNum = currentParcelNum.replace(/(\d{3})(\d{3})(\d{0,3})/, "$1-$2-$3")
                setParcelNumber(currentParcelNum);
            }
            setMessage({text: "Searching..."});   
            const response = await axios.get(`/api/parcels/search?parcelNumber=${encodeURIComponent(currentParcelNum)}`)
            .catch(() => {
                setMessage({error: true, text: 'There was an error communicating with the server. Please try again later.'});
            });

            if (response) {
                if (response?.data?.length) {
                    setSelectedParcels(response.data);            
                    setMessage({text: `${response.data.length} Parcel${response.data.length > 1 ? 's' : ''} Found`});                    
                } else {
                    setMessage({text: 'No parcels matching the search criteria were found.'});
                }
            }
        } else {
            setMessage({error: true, text: 'Please enter a parcel number with the format: 000-000-000'});   
        }
        
        setSearchInProgress(false);
    };

    const handleAddressSearch = async () => {
        clearSelectedParcels();
        setSearchInProgress(true);

        setMessage({text: "Searching..."});
        const response = await axios.get(`/api/parcels/search?address=${encodeURIComponent(address)}`)
        .catch(() => {
            setMessage({error: true, text: 'There was an error communicating with the server. Please try again later.'});
        });

        if (response) {
            if (response?.data?.length) {
                setSelectedParcels(response.data);
                setMessage({text: `${response.data.length} Parcel${response.data.length > 1 ? 's' : ''} Found`});
            } else {
                setMessage({text: 'No parcels matching the search criteria were found.'});
            }
        }

        setSearchInProgress(false);
    };

    const handleEnterSubmitParcelNumber = event => {
        if (event.key === 'Enter') {
            handleParcelNumberSearch();
        }
    };
    
    const handleEnterSubmitAddress = event => {
        if (event.key === 'Enter') {
            handleAddressSearch();
        }
    };

    const clearSelectedParcels = () => {
        setSortDirection(null);
        setSortField(null);
        setMessage(null);
        setSelectedParcels(previous => []);        
    };

    const sortResults = (event, field) => {  
        if (event.type === 'click' ||
          (event.type === 'keydown' &&
          ((event.which === 13 || event.keyCode === 13 || event.key === 'Enter') ||
          (event.which === 32 || event.keyCode === 32 || event.key === ' ')))) {
            if (sortField === field) {
                if (sortDirection === 'asc') {
                    setSortDirection('desc');
                    if (field === 'addresses') {
                        setSelectedParcels(selectedParcels.sort((x, y) => x[field][0] > y[field][0] ? 1 : -1));
                    } else {
                        setSelectedParcels(selectedParcels.sort((x, y) => x[field] > y[field] ? 1 : -1));
                    }
                } else {
                    setSortDirection('asc');
                    if (field === 'addresses') {
                        setSelectedParcels(selectedParcels.sort((x, y) => x[field][0] < y[field][0] ? 1 : -1));
                    } else {
                        setSelectedParcels(selectedParcels.sort((x, y) => x[field] < y[field] ? 1 : -1));    
                    }
                }
            } else {
                setSortField(field);
                setSortDirection('asc');
                if (field === 'addresses') {
                    setSelectedParcels(selectedParcels.sort((x, y) => x[field][0] < y[field][0] ? 1 : -1));
                } else {
                    setSelectedParcels(selectedParcels.sort((x, y) => x[field] < y[field] ? 1 : -1));    
                }
            }
        }
    };

    return (
        <>
            <BreadcrumbSocial pageTitle={"Parcel Search"}/>
            <h1 className={styles.hasSubtitle}>Parcel Search</h1>
            <p className={styles.subTitle}><a href="https://permitsonoma.org/zoning-codes/county-zones-(non-cc)">County Zoning Codes »</a></p>
            <div className={styles.parcelSearch}>
                <div className={styles.intro}>
                    <h2>Zoning & Land Use</h2>
                    <p>To search for a parcel report, enter either the parcel number in the Parcel Number search field or the physical address in the Address search field.</p>
                    <p>Alternatively, parcels can be selected by manually clicking on the outlined parcel within the map. If parcel outlines are not visible, zoom in until they are.</p>
                    <p>Once you have successfully found a parcel, the Search Results table below will populate with the Parcel Number, Address, and a Parcel Report link. The Parcel Report provides general information about the parcel in addition to its associated addresses, County issued permits, and publically available documents.</p>
                </div>
                <div className={styles.mapSearchContainer}>
                    <div className={styles.searchContainer}>
                        <p>Search by Parcel Number (<abbr title="Assessor's Parcel Number">APN</abbr>) or Address:</p>
                        <div className={styles.searchInputs}>    
                            <div className={styles.searchField}>
                                <label htmlFor="apn-input">Parcel Number</label>
                                <div className={styles.searchInput}>
                                    <input 
                                        id="apn-input"
                                        type="search" 
                                        placeholder="000-000-000" 
                                        value={parcelNumber} 
                                        onChange={event => setParcelNumber(event.target.value)} 
                                        onKeyPress={event => handleEnterSubmitParcelNumber(event)}
                                        disabled={searchInProgress}
                                    />
                                    <button 
                                        type="button" 
                                        onClick={handleParcelNumberSearch}
                                        disabled={searchInProgress}
                                        title="Search by Parcel Number"
                                    >Search</button>
                                </div>
                            </div>
                            <div className={styles.searchField}>
                                <label htmlFor="address-input">Address</label>
                                <div className={styles.searchInput}>
                                    <input 
                                        id="address-input"
                                        type="search" 
                                        placeholder="Address" 
                                        value={address} 
                                        onChange={event => setAddress(event.target.value)} 
                                        onKeyPress={event => handleEnterSubmitAddress(event)}
                                        disabled={searchInProgress}
                                    />
                                    <button 
                                        type="button" 
                                        onClick={handleAddressSearch}
                                        disabled={searchInProgress}
                                        title="Search by Address"
                                    >
                                        Search
                                    </button>
                                </div>
                            </div>   
                        </div>
                        {message?.text?.length > 0 && 
                        <>
                            <hr />
                            <p className={`${styles.statusMessage} ${message.error ? styles.error : ''}`}>{message.text}</p> 
                        </>
                        }
                    </div>         
                    <div>
                        <ParcelMap 
                            queryParcelClick={queryParcelClick}
                            removeParcel={removeParcel}
                            selectedParcels={selectedParcels}       
                            mapOptions={mapOptions}                 
                        />
                        <div className={styles.clearMap}>
                            <button 
                                type="button" 
                                onClick={clearSelectedParcels}
                                disabled={searchInProgress}
                            >
                                Reset Search Results
                            </button>
                        </div>
                    </div>
                </div>   
            </div>
            {selectedParcels?.length > 0 && 
            <ParcelSearchResults 
                results={selectedParcels} 
                sortDirection={sortDirection}
                sortField={sortField}
                sortResults={sortResults}             
            />
            }
            <Disclaimer />
        </>
    );
};