import React, { useEffect, useState } from 'react';

import styles from './TextSearch.module.scss';

export default function TextSearch({disabled, handleSearch, inputLabel, searchPrompt, searchTitle, searchType, selectedSearchType, setValue, submitRef, value}) {
    const [currentValue, setCurrentValue] = useState('');
    const [emptySubmission, setEmptySubmission] = useState(false);
    const [parcelFormatError, setParcelFormatError] = useState('');

    useEffect(() => {
        setCurrentValue(value);
    }, [value]);

    const handleChange = event => {
        setCurrentValue(event.target.value);
        setValue(event.target.value);
        setEmptySubmission(false);
    };

    const handleSubmit = () => {
        setEmptySubmission(false);
        setParcelFormatError('');
        if (currentValue) {
            if (searchType === 'parcelNumberSearch') {
                if (currentValue.match(/^(\d{6,9}|\d{3}-\d{3}(-\d{0,3}){0,1})$/)) {
                    let currentParcelNum = currentValue;
                    if (currentParcelNum.match(/^\d{6,9}$/)) {
                        currentParcelNum = currentParcelNum.replace(/(\d{3})(\d{3})(\d{0,3})/, "$1-$2-$3")
                        setCurrentValue(currentParcelNum);
                        setValue(currentParcelNum);
                    }
                    handleSearch();
                } else {
                    setParcelFormatError('Please enter a parcel number with the format: 000-000-000');
                }
            } else {
                handleSearch();
            }
        } else {
            setEmptySubmission(true);
        }
    };

    const handleEnterSubmit = event => {
        if (event.key === 'Enter') {
            handleSubmit();
        }
    };

    return (
        <div 
            id={`${searchType}_container`}
            className={`${styles.textSearchContainer}${searchType === selectedSearchType ? '' : ' hidden'}`}
        >
            <fieldset>
                <legend>{searchTitle}</legend>
                <div className={styles.search}>
                    <label htmlFor={searchType}>{inputLabel}<img src="/required_asterisk.png" alt="Required" /></label>
                    {emptySubmission &&
                    <p className="error">{searchPrompt}</p> 
                    }
                    {parcelFormatError?.length > 0 &&
                    <p className="error">{parcelFormatError}</p>
                    }
                    <input 
                        type="search" 
                        id={searchType}
                        placeholder={`Enter ${inputLabel}`}                        
                        value={currentValue}
                        onChange={handleChange}
                        onKeyPress={event => handleEnterSubmit(event)}
                        ref={submitRef ? submitRef : null} 
                        disabled={disabled} 
                    />
                </div>
                <div className={styles.searchButtonContainer}>
                    <button 
                        className={styles.searchButton}
                        type="button" 
                        onClick={handleSubmit} 
                        onKeyPress={event => handleEnterSubmit(event)}
                        disabled={disabled} 
                    >
                        Search
                    </button>
                </div>
            </fieldset>
        </div>       
    );
};