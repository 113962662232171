import React from 'react';

import styles from './ResourcesSidebar.module.scss';

export default function ResourcesSidebar() {
    return (
        <div className={styles.resources}>
            <h2>Resources</h2>
            <div className={styles.blockContent}>
                <div className={styles.phoneBlock}>
                    <h3>Phone Operator</h3>
                    <ul>
                        <li>Email: <a href="mailto:PermitSonoma@sonoma-county.org">PermitSonoma@sonoma-county.org</a></li>
                        <li>Phone: <a href="tel:+17075651900">(707) 565-1900</a></li>
                        <li>CA Relay Service: <a href="tel:711;phone-context=+1">711</a></li>
                    </ul>
                </div>
                <div className={styles.parcelCenterAddress}>
                    <h3>Permit Center &amp; Mailing Address</h3>
                    <p>Permit Sonoma</p>
                    <p>2550 Ventura Avenue</p>
                    <p>Santa Rosa, CA 95403</p>
                </div>
                <div>
                    <h3>General Resources</h3>
                    <ul>
                        <li><a href="https://permitsonoma.org/x37441.xml">Records Counter »</a></li>
                        <li><a href="https://permitsonoma.org/x83183.xml">Permit Numbering Information »</a></li>
                    </ul>
                </div>
                <div>
                    <h3>Zoning &amp; Land Use Resources</h3>
                    <ul>
                        <li><a href="https://sonomacounty.maps.arcgis.com/apps/webappviewer/index.html?id=06ac7fe1b8554171b4682dc141293962">Active Map: Zoning & Land Use »</a></li>
                        <li><a href="https://permitsonoma.org/x64781.xml">General Plan 2020 »</a></li>
                        <li><a href="https://permitsonoma.org/x91347.xml">Zoning Code Designations Explained »</a></li>
                    </ul>
                    <p>Email: <a href="mailto:Planner@sonoma-county.org">Planner@sonoma-county.org</a></p>
                    <p>Phone: <a href="tel:+17075651900">(707) 565-1900</a>, option 5</p>
                    <p>CA Relay Service: <a href="tel:711;phone-context=+1">711</a></p>
                    <p>Phone Hours:</p>
                    <div className={styles.phoneHours}>
                        <p>Monday - Friday</p>
                        <p>10:00 AM - 12:00 PM</p>
                        <p>1:00 PM - 3:00 PM</p>
                    </div>
                    <h3>Report Errors, Make Suggestions</h3>
                    <p>Report errors with Parcel or Permit History data/characteristics, or make suggestions for the tool:</p>
                    <p><a href="mailto:PRMD-Web@sonoma-county.org?subject=Parcel or Permit History - Errors or Suggestions">PRMD-Web@sonoma-county.org</a></p>
                </div>
            </div>
        </div>
    );
};