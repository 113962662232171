import React from 'react';
import { faEnvelopeOpenText } from '@fortawesome/free-solid-svg-icons';
import { faFacebookSquare, faYoutubeSquare, faTwitterSquare, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from './Footer.module.scss';

export default function Footer({socialLinks}) {
    const scrollToTop = () => {
        window.scrollTo(0, 0);
        var top = document.querySelector("header a");
        window.setTimeout(function () {
            top.focus();
        }, 200);
    };

    const renderSocialLinks = () => {
        return socialLinks?.map(linkData => {
            return renderSocialLink(linkData);
        });
    };

    const renderSocialLink = linkData => {
        let socialIcon;

        switch (linkData?.icon) {
            case 'fa-facebook-square':
                socialIcon = faFacebookSquare;
                break;
            case 'fa-twitter-square':
                socialIcon = faTwitterSquare;
                break;
            case 'fa-youtube-square':
                socialIcon = faYoutubeSquare;
                break;
            default: 
                socialIcon = faEnvelopeOpenText;
                break;
        }

        return (
            <a 
                href={linkData.link} 
                aria-label={linkData.linkText}
                key={linkData.link}
            >
                <FontAwesomeIcon icon={socialIcon} title={linkData.linkText} />
            </a>
        );
    };

    return (
        <footer>
            <div className={styles.footerHead}>
                <div className={styles.footerBar}></div>
                <div className={styles.container}>
                    <div className={styles.splitRow}>
                        <div className={styles.footerLogo}>
                            <a href="https://permitsonoma.org" aria-label="Permit Sonoma">
                                <img src="PRMD-New-Logo.png" alt="Permit Sonoma" />
                            </a>
                        </div>
                        <div className={styles.footerTitle}>
                            <h2>Permit Sonoma</h2>                            
                            <p className={styles.share}>
                                {renderSocialLinks()}
                            </p>
                            <p className={styles.signUp}>
                                <a href="https://service.govdelivery.com/accounts/CASONOMA/subscriber/new?topic_id=CASONOMA_32">
                                    <FontAwesomeIcon icon={faEnvelopeOpenText} title="Linkedin" />
                                    Sign Up for Permit Sonoma Email Updates
                                </a>
                            </p>
                        </div>
                        <div className={styles.footerContactInfo}>
                            <div>
                                <strong>Email Address</strong><br />
                                <a href="mailto:permitsonoma@sonoma-county.org">PermitSonoma@sonoma-county.org</a><br /><br />

                                <strong>Phone Operator</strong><br />
                                Phone: <a href="tel:+17075651900">(707) 565-1900</a><br />
                                CA Relay Service: <a href="tel:711;phone-context=+1">711</a>
                            </div>
                            <div>
                                <strong>In Person Hours</strong><br />
                                Monday - Tuesday,<br />
                                Thursday - Friday<br />
                                <span className={styles.indent}>8:00 AM - 4:00 PM</span><br />
                                Wednesday<br />
                                <span className={styles.indent}>10:30 AM - 4:00 PM</span>
                            </div>
                            <div>
                                <strong>Permit Center &amp; Mailing Address</strong><br />
                                2550 Ventura Avenue<br />
                                Santa Rosa, CA 95403<br />
                                <a href="https://maps.google.com/maps?f=d&amp;source=s_d&amp;hl=en&amp;ie=UTF8&amp;z=16&amp;saddr=&daddr=38.465074,-122.723705" rel="external">Google Maps™ Directions</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.footerMain}>
                <div className={styles.container}>
                    <div className={styles.splitRow}>
                        <div className={styles.footerLogo}>
                            <h3 className={styles.mobileOnly}>County of Sonoma</h3>
                            <img className={styles.desktopOnly} src="Sonoma-County-Logo-300.png" alt="County of Sonoma" />
                        </div>
                        <div className={styles.footerLinks}>
                            <div className={styles.footerLinksList}>
                                <ul>
                                    <li>
                                        <a href="https://socoemergency.org">SoCoEmergency.org</a>
                                    </li>
                                    <li>
                                        <a href="https://sonomacounty.ca.gov/administrativesupportandfiscalservices/auditor-controller-treasurer-taxcollector/divisions/revenueaccounting/propertytaxpayments">Pay Property Taxes</a>
                                    </li>
                                    <li>
                                        <a href="https://roadclosures-sonomacounty.hub.arcgis.com/">Road Closures and Delays</a>
                                    </li>
                                    <li>
                                        <a href="https://www.governmentjobs.com/careers/sonoma">County Job Opportunities</a>
                                    </li>
                                </ul>
                            </div>
                            <div className={styles.footerLinksList}>
                                <ul>
                                    <li>
                                        <a href="https://sonomacounty.ca.gov/viewallcountynewsandpressreleases/viewallcountynews">County News Archive</a>
                                    </li>
                                    <li>
                                        <a href="https://sonomacounty.ca.gov/viewallcountynewsandpressreleases/viewallcountypressreleases">County Press Release Archive</a>
                                    </li>
                                    <li>
                                        <a href="https://sonomacounty.ca.gov/countycalendar">County Event Archive</a>
                                    </li>
                                    <li>
                                        <a href="https://sonomacounty.ca.gov/viewalldepartments">County Department Directory</a>
                                    </li>
                                </ul>
                            </div>
                            <div className={styles.footerSocial}>
                                <p className={styles.social}>
                                    <a href="https://www.facebook.com/CountyofSonoma/" aria-label="Facebook">
                                        <FontAwesomeIcon icon={faFacebookSquare} title="Facebook" />    
                                    </a>
                                    <a href="https://twitter.com/CountyofSonoma?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor" aria-label="Twitter">
                                        <FontAwesomeIcon icon={faTwitterSquare} title="Twitter" />
                                    </a>
                                    <a href="https://www.youtube.com/user/officialsonomacounty" aria-label="YouTube">
                                        <FontAwesomeIcon icon={faYoutubeSquare} title="YouTube" />
                                    </a>
                                    <a href="https://www.linkedin.com/company/county-of-sonoma_2" aria-label="Linkedin">
                                        <FontAwesomeIcon icon={faLinkedin} title="Linkedin" />
                                    </a>
                                </p>
                                <p className={styles.signUp}>
                                    <a href="https://public.govdelivery.com/accounts/CASONOMA/subscriber/new?topic_id=CASONOMA_1">
                                        <FontAwesomeIcon icon={faEnvelopeOpenText} />
                                        Sign up for email updates or access your subscriber preferences
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.footerFoot}>
                <div className={styles.container}>
                    <p>©2021 County of Sonoma. All rights reserved.</p>
                    <div className={styles.links}>
                        <button 
                            type="button" 
                            className={styles.scrollTo} 
                            onClick={() => scrollToTop()}
                        >
                            Back to Top
                        </button>
                        <span className={styles.pipe}>|</span>
                        <a href="https://sonomacounty.ca.gov/accessibility-information">Accessibility Information</a>
                        <span className={styles.pipe}>|</span>
                        <a href="https://sonomacounty.ca.gov/sb272"><abbr title="Senate Bill">SB</abbr> 272</a>
                        <span className={styles.pipe}>|</span>
                        <a href="https://sonomacounty.ca.gov/terms-of-use">Terms of Use</a>
                        <span className={styles.pipe}>|</span>
                        <a href="https://sonomacounty.ca.gov/administrative-support-and-fiscal-services/information-systems/divisions/information-management/web-services/web-standards-and-guidelines">Web Standards</a>
                    </div>
                </div>
            </div>
        </footer>
    );
};