import React from 'react';

import styles from './PermitFilter.module.scss';

export default function PermitFilter({distinctPermitNumbers, handleSelectPermit, value}) {
    const renderPermitOptions = () => {
        if (distinctPermitNumbers?.length > 0) {
            return distinctPermitNumbers.map(permitNumber => {
                return (
                    <option
                        key={permitNumber}
                        value={permitNumber}
                    >
                        {permitNumber}
                    </option>
                );
            });
        }
    };

    const handleFilterChange = event => {
        handleSelectPermit(event.target.value);
    };

    return (
        <div className={styles.permitFilter}>
            <label htmlFor="permit-filter">Filter by Permit Number:</label>
            <input 
                type="search"                
                list="permit-matches"
                id="permit-filter"
                value={value}
                onInput={event => handleFilterChange(event)}
                onChange={event => handleFilterChange(event)}
            />
            <datalist id="permit-matches">
                {renderPermitOptions()}
            </datalist>
        </div>
    );
};