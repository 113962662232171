import React, { useState } from 'react';

import styles from './AddressList.module.scss';

export default function AddressList({addresses}) {    
    const [expanded, setExpanded] = useState(false);

    const renderAddresses = () => {
        return addresses.map((address, index) => {
            if (expanded || index < 5) {
                return (
                    <li 
                        key={`${address.address}_${index}`}
                    >
                        {address}
                    </li>
                );
            }
            return null;
        });
    };

    const handleToggleAddresses = () => {
        setExpanded(!expanded);
    };

    return (
        <>
            {addresses?.length > 0 ?
            <ul className={styles.addresses}>
                {renderAddresses()}
            </ul>
            : 
            <>
                <p>
                    No Address on Record<br />
                    <a href="https://permitsonoma.org/divisions/customer-service-and-administration/addressing-and-road-naming" target="_blank" rel="noreferrer">Find out more about Addressing</a>
                </p>
            </>
            }
            {addresses && addresses.length > 5 ? 
            <button 
                type="button" 
                onClick={handleToggleAddresses}
                className={`${styles.showHideButton} ${expanded ? styles.expanded : ''}`}
            >
                    {expanded ? `Hide excess addresses ▼` : `Show all ${addresses.length} addresses ►`}
            </button>
            : null}
        </>
    );
};