import React from 'react';

import styles from './PermitPrintTable.module.scss';

export default function PermitPrintTable({permits, permitPrinting}) {
    const renderPermits = () => {
        if (permits?.length > 0) {
            return permits.map(permit => {
                return (
                    <tr key={permit.permitNumber}>
                        <th scope="col">{permit.permitNumber}</th>
                        <td>{new Date(permit.fileDate).toLocaleDateString()}</td>
                        <td>{permit.permitStatus}</td>
                        <td>{permit.permitTypeAlias}</td>
                        <td>{permit.workDescription}</td>                        
                    </tr>
                )
            });
        }
    };

    return (
        <div className={`${styles.permitPrintTable}${permitPrinting ? ` ${styles.noPrint}` : ''}`}>
            <table>               
                <thead>
                    <tr>
                        <th scope="col">
                            <p>Permit Number</p>
                        </th>
                        <th scope="col">
                            <p>Date</p>
                        </th>
                        <th scope="col">
                            <p>Status</p>
                        </th>
                        <th scope="col">
                            <p>Permit Type</p>
                        </th>
                        <th scope="col">
                            <p>Description</p>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {renderPermits()}
                </tbody>
            </table>
        </div>
    );
};