import React, { useState } from 'react';

import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import SubNavLink from './SubNavLink';

import styles from './TopNavLink.module.scss';

export default function TopNavLink({linkData, index, subMenuVisible, handleBlurMenu, handleFocusMenu, handleMainNavKeypress}) {
    const [childExpanded, setChildExpanded] = useState(false);

    const renderSubNavLinks = () => {
        return linkData.children.map((childLink, subIndex) => {
            return (
                <SubNavLink                    
                    key={`${index}__${subIndex}`}
                    linkData={childLink}
                    handleBlurMenu={handleBlurMenu}                    
                />
            )
        })
    };

    return (
        <li 
            key={`dropdownLinks_${index}`}
            className={`topLevelNav ${styles.topLevelNav}`}     
            onKeyDown={event => handleMainNavKeypress(event, index)}
        >
            <div>
                <a 
                    href={linkData.link}
                    onBlur={(event) => handleBlurMenu(event)}
                    onFocus={() => handleFocusMenu(index)}
                    className="topNavLink"
                >
                    {linkData.linkText}
                </a> 
                {linkData?.children?.length > 0 &&
                    <button type="button" onClick={() => setChildExpanded(!childExpanded)}>
                    {childExpanded ? 
                        <FontAwesomeIcon icon={faMinus} title="Collapse" />
                    : 
                        <FontAwesomeIcon icon={faPlus} title="Expand" />
                    }
                    </button>
                }
            </div>
            {linkData?.children?.length > 0 && 
            <ul className={`${subMenuVisible ? styles.focusVisible : ''} ${childExpanded ? styles.mobileVisible : ''}`}>
                {renderSubNavLinks()}
            </ul>
            }
        </li>
    );
};