import React from 'react';

import styles from './PermitTypeButton.module.scss';

export default function PermitTypeButton({displayedType, permitCount, permitType, togglePermitTypeDisplay}) {
    let buttonText = '';

    switch (permitType.toLowerCase()) {
        case "all": 
            buttonText = `Show All (${permitCount})`;
            break;
        case "agcomm":
            buttonText = `Ag Commission (${permitCount})`;
            break;
        case "building":
            buttonText = `Building (${permitCount})`;
            break;
        case "customerservice":
            buttonText = `Customer Service (${permitCount})`;
            break;
        case "enforcement":
            buttonText = `Enforcement (${permitCount})`;
            break;
        case "engineering":
            buttonText = `Engineering (${permitCount})`;
            break;
        case "fire":
            buttonText = `Fire (${permitCount})`;
            break;
        case "planning":
            buttonText = `Planning (${permitCount})`;
            break;
        case "wellandseptic":
            buttonText = `Well and Septic (${permitCount})`;
            break;
        default:
            buttonText = `${permitType} (${permitCount})`;
            break;
    };

    const handleButtonPress = event => {
        if (event.key === "Enter" || event.key === "Space") {
            togglePermitTypeDisplay(permitType);
        }
    };

    return (
        <button
            type="button"
            className={`${styles.permitTypeSelectBtn} ${displayedType?.toLowerCase() === permitType.toLowerCase() ? styles.active : ''}`}
            onClick={() => togglePermitTypeDisplay(permitType)}
            onKeyPress={handleButtonPress}
        >
            {buttonText}
        </button>
    );
};