import React from 'react';

import TimelineArrow from './TimelineArrow';
import TimelineBox from './TimelineBox';

import styles from './PermitTimeline.module.scss';

export default function PermitTimeline({steps}) {
    const renderSteps = () => {
        return steps.map((step, i) => {
            if (i < steps.length - 1) {
                return (
                    <React.Fragment key={`step_${i}`}>
                        <TimelineBox 
                            step={step.step} 
                            finalDate={step.finalStepDate} 
                        />
                        <TimelineArrow />
                    </React.Fragment>
                );
            } else {
                return (
                    <React.Fragment key={`step_${i}`}>
                        <TimelineBox 
                            step={step.step} 
                            finalDate={step.finalStepDate} 
                        />
                    </React.Fragment>
                );
            }
        });      
    };

    return (
        <div className={styles.permitTimeline}>
            {steps?.length > 0 && (
            <>
                <h3>Permit Timeline</h3>
                <div className={`${styles.timelineSteps} ${styles[`process_${steps.length}`]}`}>
                    {renderSteps()}
                </div>
            </>
            )}
        </div>
    );
};