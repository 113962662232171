import React from 'react';

import DetailedTimelineStep from './DetailedTimelineStep';

import styles from './DetailedPermitTimeline.module.scss';

export default function DetailedPermitTimeline({display, permitData}) {
    const renderTimelineSteps = () => {
        return permitData.steps.map(step => {
            if (step?.activityGroups?.length > 0) {
                return <DetailedTimelineStep 
                    key={`${permitData.permitNumber}_${step.step}`} 
                    permitNumber={permitData.permitNumber}
                    step={step}
                />;
            } else {
                return null;
            }
        });
    };

    return (
        <div 
            id={`timeline_${permitData.permitNumber}`}
            className={`${styles.detailedTimeline} ${display ? '' : 'hidden'}`}
        >
            {renderTimelineSteps()}
        </div>
    );
};