import { Route, Routes, Navigate } from 'react-router';
import React, { useState } from 'react';

import Layout from './components/Layout';
import ParcelReport from './components/ParcelReport/ParcelReport';
import ParcelSearch from './components/ParcelSearch/ParcelSearch';
import PermitSearch from './components/PermitSearch/PermitSearch';
import Home from './components/Home/Home';
import PageNotFound from './components/common/PageNotFound';

export default function App() {
    const [lastParcelSearch, setLastParcelSearch] = useState(null);
    const [lastPermitSearch, setLastPermitSearch] = useState(null);   

    return (
        <Layout>      
            <Routes>
                <Route
                    exact
                    path='/'
                    element={<Home />}
                />
                <Route
                    exact
                    path='/parcelsearch'
                    element={
                        <ParcelSearch
                            lastParcelSearch={lastParcelSearch}
                            setLastParcelSearch={setLastParcelSearch}
                        />
                    }
                />                
                <Route
                    exact
                    path='/permitsearch'
                    element={
                        <PermitSearch
                            lastPermitSearch={lastPermitSearch}
                            setLastPermitSearch={setLastPermitSearch}
                        />
                    }
                />
                <Route                     
                    path='/parcelreport'
                    element={
                        <ParcelReport />
                    }
                />
                <Route 
                    path='/404'
                    element={
                        <PageNotFound />
                    }
                />
                <Route 
                    path="*"
                    element={
                        <Navigate to='/404' replace />
                    }
                />
            </Routes>          
        </Layout>
    );
};