import React, { useEffect, useState } from 'react';
import axios from 'axios';

import Header from './common/Header';
import Footer from './common/Footer';

import styles from './Layout.module.scss';

export default function Layout({ children }) {
    const [headerLinks, setHeaderLinks] = useState(null);
    const [socialLinks, setSocialLinks] = useState(null);

    useEffect(() => {
        const fetchNavigationData = async () => {
            const result = await axios.get('/api/navigation');
            
            if (result?.data?.headerLinks) {
                setHeaderLinks(result.data.headerLinks);
            }

            if (result?.data?.socialLinks) {
                setSocialLinks(result.data.socialLinks);
            }
        };

        const handleVerticalScroll = () => {
            const body = document.querySelector('body');
            const overflowSize = Number(window.getComputedStyle(body).height.replace('px', '')) - window.innerHeight;
            
            if ((window.scrollY !== undefined && window.scrollY === 0) || (window.pageYOffset !== undefined && window.pageYOffset === 0)) {
                body.classList.add('scrolledTop');
            } else if (body.classList.contains('scrolledTop') && overflowSize > -120) {
                body.classList.remove('scrolledTop');     
            }
        }

        fetchNavigationData();

        document.addEventListener('scroll', handleVerticalScroll);
        window.addEventListener('resize', handleVerticalScroll);
        document.querySelector('body').classList.add('scrolledTop');

        return () => {
            document.removeEventListener('scroll', handleVerticalScroll); 
            window.removeEventListener('resize', handleVerticalScroll);
        } 
    }, []);    

    return (
        <>
            <Header headerLinks={headerLinks} />
            <main>
                <div className={styles.innerMain}>
                    {children}
                </div>
            </main>
            <Footer socialLinks={socialLinks} />
        </>
    );
};