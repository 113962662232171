import { Link } from 'react-router-dom';
import React from 'react';

import AddressList from '../common/AddressList';

export default function ParcelSearchResults({results, sortDirection, sortField, sortResults}) {
    const renderResults = () => {
        let count = 0;

        if (results) {
            return results.map(result => {
                return (
                    <tr key={`${result.parcelNumber}_${count++}`}>
                        <th scope="row">{result.parcelNumber}</th>
                        <td>
                            <AddressList addresses={result.addresses} />
                        </td>
                        <td>
                            <Link to={`/ParcelReport?APN=${result.parcelNumber}&RP=parcelSearch`}>
                                View Report
                            </Link>                            
                        </td>
                    </tr>
                );
            });
        }
    };

    return (
        <div>
            <h2>Search Results</h2>
            <table>
                <thead>
                    <tr>
                        <th scope="col">
                            <button
                                type="button"
                                onClick={event => sortResults(event, 'parcel')}                                
                            >
                                <div>
                                    <span>Parcel Number</span>
                                    <span>{sortField === 'parcel' ? sortDirection === 'asc' ? '▲' : '▼' : null}</span>
                                </div>
                            </button>
                        </th>
                        <th scope="col">
                            <button
                                type="button"
                                onClick={event => sortResults(event, 'addresses')}
                            >
                                <div>
                                    <span>Address</span>
                                    <span>{sortField === 'addresses' ? sortDirection === 'asc' ? '▲' : '▼' : null}</span>
                                </div>
                            </button>	      
                        </th>
                        <th scope="col">
                            <p>
                                Parcel Report
                            </p>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {renderResults()}
                </tbody>
            </table>
        </div>
    );
};