import React from 'react';

import styles from './PermitSearchTypeButton.module.scss';

export default function PermitSearchTypeButton({active, buttonText, handleSearchTypeChange, searchType}) {
    return (
        <button
            type="button" 
            aria-expanded={active}
            aria-controls={`${searchType}_container`}
            className={`${styles.searchTypeButton} ${active ? styles.active : ''}`} 
            onClick={event => handleSearchTypeChange(event, searchType)}
        >
            <span className={styles.buttonText}>{buttonText}</span>
            {active ? <span>&#9660;</span> : <span>&#9654;</span>}
        </button>
    );
};