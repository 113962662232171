import React from 'react';

import styles from './Activity.module.scss';

export default function Activity({activity, activityDescription, permitNumber}) {
    const renderComments = () => {
        return activity.comments.map(comment => {
            return (                
                <li 
                    key={`${permitNumber}_${activityDescription}_${activity.activityStatus}_${activity.activityDate}_${comment.contact}_${comment.comment}`}
                    className={styles.comments}
                >
                    {comment.contact ? <p>Updated by: {comment.contact}</p> : null}
                    {comment.comment ? <p>{comment.comment}</p> : null}
                </li>
            );
        });
    };

    return (
        <li>
            <div className={styles.activity}>
                {activity?.activityDate ? <p>{activity.activityStatus}</p> : null}
                {activity?.activityDate ? <p>{new Date(activity.activityDate).toLocaleDateString()}</p> : null}
            </div>
            {activity?.comments?.length > 0 ? 
            <ul className={styles.activityComments}>
                {renderComments()}
            </ul>
            : null}
        </li>
    );
};