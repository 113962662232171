import React from 'react';

import styles from './OrSplitter.module.scss';

export default function OrSplitter() {
    return (
        <div className={styles.orText}>
            <div>
                <div className={styles.orTop}></div>
                <div className={styles.orBottom}></div>
            </div>
            <p>Or</p>
            <div>
                <div className={styles.orTop}></div>
                <div className={styles.orBottom}></div>
            </div>
        </div>
    );
};