import React from 'react';

import TabSelectButton from './TabSelectButton';

import styles from './ParcelReportTabButtons.module.scss';

export default function ParcelReportTabButtons({currentTab, setCurrentTab}) {
    const handleNavigateTabs = event => {
        if (event.which === 37 || event.keyCode === 37 || event.key === 'ArrowLeft') {
            if (event?.target?.previousSibling) {
                event.target.previousSibling.focus();
            } else if (event?.target?.parentElement?.lastChild) {
                event.target.parentElement.lastChild.focus();
            }
        } else if (event.which === 39 || event.keyCode === 39 || event.key === 'ArrowRight') {
            if (event?.target?.nextSibling) {
                event.target.nextSibling.focus();
            } else if (event?.target?.parentElement?.firstChild) {
                event.target.parentElement.firstChild.focus();
            }
        } else if (event.which === 36 || event.keyCode === 36 || event.key === 'Home') {
            event.preventDefault();
            if (event?.target?.parentElement?.firstChild) {
                event.target.parentElement.firstChild.focus();
            }
        } else if (event.which === 35 || event.keyCode === 35 || event.key === 'End') {
            event.preventDefault();
            if (event?.target?.parentElement?.lastChild) {
                event.target.parentElement.lastChild.focus();
            }
        } else if (!event.shiftKey && (event.which === 9 || event.keyCode === 9 || event.key === 'Tab')) {
            event.preventDefault();
            let selectedTab = document.querySelector(`.tabContent:not(.hidden)`);
            if (selectedTab) {
                selectedTab.focus();
            }
        }
    };

    const handleTabSelect = category => {
        setCurrentTab(category);
    };

    return (
        <div 
            className={styles.tabButtons} 
            role="tablist"
            onKeyDown={handleNavigateTabs}
        >
            <TabSelectButton
                category="parcel"
                currentTab={currentTab}
                handleTabSelect={() => handleTabSelect('parcel')}
                aria-controls="parcel-tab"
                buttonText="Parcel Detail"
            />
            <TabSelectButton
                category="permits"
                currentTab={currentTab}
                handleTabSelect={() => handleTabSelect('permits')}
                aria-controls="permits-tab"
                buttonText="Permit History"
            />
            <TabSelectButton
                category="documents"
                currentTab={currentTab}
                handleTabSelect={() => handleTabSelect('documents')}
                aria-controls="documents-tab"
                buttonText="Documents"
            />
        </div>
    );
};