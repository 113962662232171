import React from 'react';
import { Link } from 'react-router-dom';

import styles from './Breadcrumbs.module.scss';

export default function Breadcrumbs({pageTitle}) {
    return (
        <div className={styles.crumbs}>
            <a href="https://permitsonoma.org">Permit Sonoma</a>
            <span className={styles.pipe}>&#8250;</span>
            <a href="https://permitsonoma.org/permitservices">Permit Services</a>
            <span className={styles.pipe}>&#8250;</span>
            <Link to="/">Permit and Parcel Search</Link>
            <span className={styles.pipe}>&#8250;</span>
            <span>{pageTitle}</span>
        </div>
    );
};