import React from 'react';

import AddressList from '../../common/AddressList';

import styles from './ParcelDetail.module.scss';

const COASTAL_ZONING_CODE_PATTERN = /\s(CC)\s/;

export default function ParcelDetail({addresses, addressesError, parcelReportData, parcelReportError}) {
    const renderParcelDetail = () => {
        if (parcelReportData) {
            return (
                <div>
                    {parcelReportData?.baseFields?.length > 0 && renderFields(parcelReportData.baseFields)}
                    {parcelReportData?.zoningFields?.length > 0 && renderFields(parcelReportData.zoningFields)}
                    {parcelReportData?.remainingFields?.length > 0 && renderFields(parcelReportData.remainingFields, true)}
                </div>
            );
        }
    };

    const renderTobaccoLicenseDefinition = value => {
        if (value === 'true') {
            return (
                <React.Fragment>
                    Zoning may allow sale of tobacco; requires local Tobacco Retail License. Refer to <a href="https://sonomacounty.ca.gov/health-and-human-services/health-services/divisions/public-health/tobacco-prevention/tobacco-retailers#local" target="_blank" rel="noreferrer">Sonoma County Department of Health Services</a>
                </React.Fragment>
            );
        } else if (value === 'incorporated') {
            return (
                <React.Fragment>
                    Refer to <a href="https://sonomacounty.ca.gov/health-and-human-services/health-services/divisions/public-health/tobacco-prevention/tobacco-retailers#local" target="_blank" rel="noreferrer">Sonoma County Department of Health Services</a>
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    Zoning prohibits sale of tobacco
                </React.Fragment>
            );
        }
    }
    
    const renderFields = (fields, last = false) => {
        return (
            <>
                <dl>
                    {fields.map(field => {
                        if (field.label === 'Jurisdiction' && field.value !== 'Unincorporated Sonoma County') {
                            return (
                                <React.Fragment key={field.label}>
                                    <dt>{field.label}</dt>
                                    <dd>
                                        <a href={`https://sonomacounty.ca.gov/local-government#${field.value}`} target="_blank" rel="noreferrer">Within City of {field.value}</a>
                                    </dd>
                                </React.Fragment>    
                            );
                        } else if (field.label === 'Zoning') {
                            if (field.value.match(COASTAL_ZONING_CODE_PATTERN) !== null) {
                                return (
                                    <React.Fragment key={field.label}>
                                        <dt>{field.label}</dt>
                                        <dd>
                                            {field.value}
                                            <br />
                                            <a href="https://permitsonoma.org/zoningcodes/coastalzone(cc)" target="_blank" rel="noreferrer">Find Out What the Zoning Codes Mean</a>
                                        </dd>
                                    </React.Fragment>
                                );
                            } else {
                                return (
                                    <React.Fragment key={field.label}>
                                        <dt>{field.label}</dt>
                                        <dd>
                                            {field.value}
                                            <br />
                                            <a href="https://permitsonoma.org/zoningcodes/countyzones(non-cc)" target="_blank" rel="noreferrer">Find Out What the Zoning Codes Mean</a>
                                        </dd>
                                    </React.Fragment>
                                );
                            }
                        } else if (field.label === 'Tobacco Retail License') {
                            return (
                                <React.Fragment key={field.label}>
                                    <dt>{field.label}</dt>
                                    <dd>
                                        {renderTobaccoLicenseDefinition(field.value)}
                                    </dd>
                                </React.Fragment>
                            )
                        } else {
                            return (
                                <React.Fragment key={field.label}>
                                    <dt>{field.label}</dt>
                                    <dd>{field.value}</dd>
                                </React.Fragment>
                            );
                        }
                    })}
                </dl>
                {!last && 
                <hr />
                }
            </>
        );
    };

    return (
        <>
            <div className={styles.blockContainer}>
                <h2>Associated Addresses</h2>
                <div className={`${styles.blockContent} ${styles.addresses}`}>
                    {addresses?.length > 0 &&
                    <AddressList addresses={addresses} />
                    }
                    {addressesError?.length > 0 && 
                    <p className={styles.noResults}>{addressesError}</p>
                    }
                    {addressesError === null && addresses === null && 
                    <p className={styles.noResults}>Loading...</p>
                    }
                </div>
            </div>
            <div className={styles.blockContainer}>
                <h2>Parcel Report</h2>
                <div className={styles.blockContent}>
                    {renderParcelDetail()}
                    {parcelReportError?.length > 0 && 
                    <p className={styles.noResults}>{parcelReportError}</p>
                    }
                    {parcelReportError === null && parcelReportData === null && 
                    <p className={styles.noResults}>Loading...</p>
                    }
                </div>
            </div>
        </>
    );
};