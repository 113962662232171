import React from 'react'

import styles from './PermitPopup.module.scss';

export default function PermitPopup({parcel}) {
    const getPermitTypeText = permitType => {
        switch (permitType.toLowerCase()) {
            case "building": 
                return "Building";
            case "wellandseptic": 
                return "Well and Septic";
            case "engineering": 
                return "Engineering";
            case "enforcement":
                return "Enforcement";
            case "planning":
                return "Planning";
            case "customerservice": 
                return "Customer Service";
            case "agcomm": 
                return "Agricultural Commissioner";
            case "fire": 
                return "Fire";
            default: 
                return permitType;
        }
    }

    const generatePermitPopupRow = () => {
        return parcel.permits.map(permit => {
            return (
                <div 
                    className={styles.permitRow}
                    key={`${parcel.parcelNumber}_${permit.permitNumber}`}
                >
                    <a href={`/parcelreport?APN=${permit.parcelNumber}&RP=permitSearch&PN=${permit.permitNumber}`}>{permit.permitNumber} ({getPermitTypeText(permit.type)})</a>
                    <span className="label">{permit.status}</span>
                </div>
            );
        });
    };
    
    return (
        <div className={styles.popupPermits}>
            <div className={styles.innerPermits}>
                {generatePermitPopupRow()}
            </div>
            <p>Go to <a href={`/parcelreport?APN=${parcel.attributes.apn}&RP=permitSearch`}>Parcel Report</a> to see more details</p>
        </div>
    );
};