import React from 'react';

import styles from './DetailToggleButton.module.scss';

export default function DetailToggleButton({detailDisplayed, numDocuments, permitNumber, setDetailDisplayed, toggleType}) {
    const renderButtonText = () => {
        if (toggleType === 'timeline') {
            if (detailDisplayed === 'timeline') {
                return 'Hide Timeline ▼';
            } else {
                return 'Show Timeline ►';
            }
        } else if (toggleType === 'documents') {
            if (detailDisplayed === 'documents') {
                return `Hide Documents ▼`;
            } else {
                return `Show Documents (${numDocuments}) ►`;
            }
        }
    };

    return (
        <button
            type="button"
            className={styles.detailToggle}
            aria-controls={`${toggleType}_${permitNumber}`}
            aria-expanded={toggleType === detailDisplayed}
            onClick={() => setDetailDisplayed(toggleType === detailDisplayed ? null : toggleType)}
        >
            {renderButtonText()}
        </button>
    );
};