import { useEffect, useRef } from 'react';

export default function useClickOutside() {
    const containerRef = useRef();
    const firstElementRef = useRef();

    const handleClickOutside = event => {
        if (containerRef?.current && !containerRef?.current?.contains(event.target)) {
            firstElementRef.current.focus();
        }
    };

    useEffect(() => {
        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };      
    });

    return [containerRef, firstElementRef];
};