import React, { useState, useRef, useEffect } from 'react';
import { setDefaultOptions, loadModules, loadCss } from 'esri-loader';
import usePrevious from '../../../hooks/usePrevious';

import ControlOverlay from './ControlOverlay';

import styles from './Map.module.scss';

const parcelLayerUrl = "https://services1.arcgis.com/P5Mv5GY5S66M8Z1Q/ArcGIS/rest/services/Parcel_Countywide/FeatureServer/0";
const cityLimitsLayerUrl = "https://services1.arcgis.com/P5Mv5GY5S66M8Z1Q/ArcGIS/rest/services/City_Limits_PSGIS/FeatureServer/0";

let modules = null;
let selectedParcelsLayer = null;
let parcelLayer = null;
let cityLimitsLayer = null;
let parcelMap = null;
let view = null;
let home = null;

export default function ParcelMap({mapOptions, parcelNumber, queryParcelClick, removeParcel, selectedParcels, permitPrinting}) {
    const [controlOverlay, setControlOverlay] = useState(false);
    const [mapInitialized, setMapInitialized] = useState(false);
    const previousSelectedParcels = usePrevious(selectedParcels);    
    const [queuedParcels, setQueuedParcels] = useState([]);
    const mapRef = useRef();
    
    useEffect(() => {    
        const initializeMap = async () => {
            if (!modules) {
                await loadGISModules();
            }

            selectedParcelsLayer = new modules.GraphicsLayer({
                title: "GraphicsLayer",
                minScale: 50000,
                listMode: "hide"
            });

            let layers = [selectedParcelsLayer];

            parcelLayer = new modules.FeatureLayer({
                url: parcelLayerUrl,
                outFields: ["*"],
                opacity: 0.5,
                minScale: 10000,
                renderer: new modules.SimpleRenderer({
                    symbol: new modules.SimpleFillSymbol({
                        color: [140, 150, 150, .75],
                        style: "solid",
                        outline: {
                            color: "black",
                            width: 1
                        }
                    })
                }),
                definitionExpression: ["APN LIKE '%%'"],
                listMode: "hide"
            });

            if (mapOptions.parcelLayer.display) {
                layers.push(parcelLayer);
            }

            cityLimitsLayer = new modules.FeatureLayer({
                url: cityLimitsLayerUrl,
                outFields: [],
                opacity: 0.5,
                minScale: 10000000,
                renderer: new modules.SimpleRenderer({
                    symbol: new modules.SimpleFillSymbol({
                        color: [0, 0, 0, 0.2],
                        style: "solid",
                        outline: {
                            width: 0
                        }
                    })
                })
            });

            if (mapOptions.cityLimitsLayer.display) {
                layers.push(cityLimitsLayer);
            }

            parcelMap = new modules.Map({
                basemap: "topo",
                layers
            });

            view = new modules.MapView({
                container: mapRef.current,
                map: parcelMap,
                zoom: 16,
                center: new modules.Point({
                    "longitude": -122.723537,
                    "latitude": 38.465099
                })
            });

            home = new modules.Home({
                view: view
            });
        
            view.ui.add(home, "top-left");

            if (mapOptions.enableFeatureAddOnClick) {
                view.on('click', handleMapClick);
            }

            view.on('mouse-wheel', event => {            
                if (!event?.native?.ctrlKey) {
                    event.preventDefault();
                    event.stopPropagation();
                    setControlOverlay(true);
                }
            });

            setMapInitialized(true);
        }

        const loadGISModules = async () => {
            setDefaultOptions({ version: 4.16 });
            const [
                Map,
                MapView,
                Point,
                FeatureLayer,
                SimpleFillSymbol,
                GraphicsLayer,
                Home,
                SimpleRenderer
            ] =  await loadModules([
                'esri/Map',
                'esri/views/MapView',
                'esri/geometry/Point',
                'esri/layers/FeatureLayer',
                'esri/symbols/SimpleFillSymbol',
                'esri/layers/GraphicsLayer',
                'esri/widgets/Home',
                'esri/renderers/SimpleRenderer'
            ]);
            
            modules = {
                Map,
                MapView,
                Point,
                FeatureLayer,
                SimpleFillSymbol,
                GraphicsLayer,
                Home,
                SimpleRenderer
            }
        };

        if (!mapInitialized) {
            loadCss();
            initializeMap();
        }
    }, [mapOptions, mapInitialized]);

    useEffect(() => {
        const queryParcelNumber = async () => {
            selectedParcelsLayer.removeAll();

            let query = parcelLayer.createQuery();

            query.where = `APN = '${parcelNumber}'`;
            query.outFields = "APN,OBJECTID";
            
            const result = await parcelLayer.queryFeatures(query);

            if (result?.features && result.features.length > 0) {
                highlightParcel(result.features[0]);

                const layerView = await view.whenLayerView(selectedParcelsLayer);
                
                if (layerView) {
                    const response = await layerView.queryGraphics();
                
                    if (response) {
                        view.goTo(response);
                    }
                }
            }
        };
        
        if (mapInitialized && parcelNumber && selectedParcelsLayer && parcelLayer && view) {
            queryParcelNumber();
        }
    }, [parcelNumber, mapInitialized]);

    useEffect(() => {
        const addNewFeatures = async (newFeatures) => {
            let query = parcelLayer.createQuery();
            query.outFields = "APN,OBJECTID";

            if (newFeatures.length > 1) {
                query.where = `APN IN ('${newFeatures.map(parcel => parcel.parcelNumber).join("','")}')`;
            } else if (newFeatures.length === 1) {
                query.where = `APN = '${newFeatures[0].parcelNumber}'`;
            }
    
            let result = await parcelLayer.queryFeatures(query);

            if (result?.features?.length > 0) {
                for (let feature of result.features) {
                    highlightParcel(feature);
                }
    
                let layerView = await view.whenLayerView(selectedParcelsLayer);
                if (layerView) {
                    let response = await layerView.queryGraphics();
                    if (response) {
                        await view.goTo(response);
                    }
                }
            }
        };

        const processSelectedParcelsChange = async () => {
            if (!selectedParcels || selectedParcels.length === 0) {
                selectedParcelsLayer.graphics.removeAll();
            } else {
                const newFeatures = selectedParcels.filter(parcel => !previousSelectedParcels.includes(parcel));
    
                if (newFeatures && newFeatures.length > 0) {
                    addNewFeatures(newFeatures);
                }
            }
        };

        if (parcelLayer && view && selectedParcelsLayer) {
            processSelectedParcelsChange();
        }
    }, [previousSelectedParcels, selectedParcels]);

    useEffect(() => {
        if (queuedParcels && queuedParcels.length > 0) {
            let newQueuedParcels = [...queuedParcels];
            let currentParcel = newQueuedParcels.shift();

            if (selectedParcels.some(parcel => parcel.parcelNumber === currentParcel.parcelNumber)) {
                removeParcel(currentParcel.parcelNumber);
                selectedParcelsLayer.graphics.remove(currentParcel.graphic);
            } else {
                queryParcelClick(currentParcel.parcelNumber);
            }

            setQueuedParcels(newQueuedParcels);
        }
    }, [removeParcel, queuedParcels, queryParcelClick, selectedParcels]);

    const handleMapClick = async event => {       
        let response = await view.hitTest(event.screenPoint);
        if (response?.results && response.results.length > 0) {                        
            let parcelNumber;
            let graphic = null;

            let filteredParcelLayer = response.results.filter(layerResult => {
                return layerResult.graphic.layer === parcelLayer;
            });

            if (filteredParcelLayer?.[0]?.graphic?.attributes?.['APN']) {                
                parcelNumber = filteredParcelLayer[0].graphic.attributes['APN'];
            }

            let filteredSelectedParcelsLayer = response.results.filter(layerResult => {
                return layerResult.graphic.layer === selectedParcelsLayer;
            });

            if (filteredSelectedParcelsLayer?.[0]?.graphic) {
                graphic = filteredSelectedParcelsLayer[0].graphic;
            }

            if (parcelNumber) {
                setQueuedParcels(queue => [...queue, {
                    parcelNumber,
                    graphic
                }]);
            }
        }
    };

    const highlightParcel = feature => {
        if (feature) {
            selectedParcelsLayer.graphics.add({
                geometry: feature.geometry,
                symbol: new modules.SimpleFillSymbol({
                    color: [100, 135, 175, 0.35],
                    style: "solid",
                    outline: {
                        color: [75, 100, 150],
                        width: 2
                    }
                })
            });
        }
    };

    return (
        <div className={`${styles.mapContainer}${permitPrinting ? ` ${styles.noPrint}` : ''}`}>
            <ControlOverlay
                controlOverlay={controlOverlay}
                setControlOverlay={setControlOverlay}
                page={mapOptions.page}
            />
            <div className={styles.map} ref={mapRef}></div>
        </div>
    );
};