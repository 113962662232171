import React from 'react';

import PermitDocument from './PermitDocument';

import styles from './DocumentListing.module.scss';

export default function DocumentListing({documentsError, permits}) {
    const renderAllDocuments = () => {
        if (permits?.length > 0) {
            return permits.reduce((accumulator, permit) => {
                if (permit?.documents?.length > 0) {
                    return [...accumulator, (
                        <div 
                            key={`${permit.permitNumber}_documents`}
                            className={styles.permitDocument}
                        >
                            <h3>{permit.permitNumber}</h3>
                            {renderDocuments(permit.documents)}
                        </div>
                    )];
                } 
                return accumulator;
            }, []);
        }
    };

    const renderDocuments = (documents) => {
        return documents.map(document => {
            return  <PermitDocument
                key={`listing_${document.id}`}
                permitDocument={document}
            />;
        });
    };

    return (
        <div className={styles.documentListing}>
            <h2>Documents</h2>
            <div className={styles.documentListingInner}>
                {renderAllDocuments()}                
                {documentsError?.length > 0 &&
                <p className={styles.errorBox}>{documentsError}</p>
                }
                {documentsError === null && permits === null &&
                <p className={styles.errorBox}>Loading...</p>
                }
            </div>
        </div>
    );
};