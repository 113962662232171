import React from 'react';

import styles from './TimelineBox.module.scss';

export default function TimelineBox({finalDate, step}) {
    return (
        <div className={`${styles.process} ${finalDate ? styles.processComplete : styles.processPending}`}>
            <p>{step}</p>
            <span className={styles.displayHidden}>{finalDate ? 'Active or Complete' : 'Pending'}</span>
            {finalDate ? <p>{new Date(finalDate).toLocaleDateString()}</p> : null}
        </div>
    );
};