import React from 'react';

import styles from './PermitDocument.module.scss';

export default function PermitDocument({permitDocument}) {   
    const renderDownloadIcon = () => {
        let imageSource = '/prmd-parcel-rpt-generic.png';
        let altText = 'Download Document';

        if (permitDocument?.name) {
            let match = permitDocument.name.toLowerCase().match(/\.([0-9a-z]+)$/);

            if (match?.length > 1) {
                switch (match[1]) {
                    case "doc":
                    case "docx":
                    case "docm":
                    case "dotx":
                    case "dotm":
                    case "docb":
                        imageSource = '/prmd-parcel-rpt-doc.png';
                        altText = 'Download Word Document';
                        break;
                    case "ppt":
                    case "pot":
                    case "pps":
                    case "pptx":
                    case "pptm":
                    case "potx":
                    case "potm":
                    case "ppam":
                    case "ppsx":
                    case "ppsm":
                    case "sldx":
                    case "sldm":
                        imageSource = '/prmd-parcel-rpt-ppt.png';
                        altText = 'Download Powerpoint Presentation';
                        break;
                    case "application/vnd.ms-excel":
                    case "xls":
                    case "xlt":
                    case "xlm":
                    case "xlsx":
                    case "xlsm":
                    case "xltx":
                    case "xltm":
                    case "xlsb":
                    case "xla":
                    case "xlam":
                    case "xll":
                    case "xlw":
                        imageSource = '/prmd-parcel-rpt-xls.png';
                        altText = 'Download Excel Document';
                        break;
                    case "tif":
                    case "tiff":
                        imageSource = '/prmd-parcel-rpt-tif.png';
                        altText = 'Download TIF Image';
                        break;
                    case "gif":
                        imageSource = '/prmd-parcel-rpt-gif.png';
                        altText = 'Download GIF Image';
                        break;
                    case "jpg":
                    case "jpeg":
                        imageSource = '/prmd-parcel-rpt-jpg.png';
                        altText = 'Download JPG Image';
                        break;
                    case "png":
                        imageSource = '/prmd-parcel-rpt-png.png';
                        altText = 'Download PNG Image';
                        break;
                    case "pdf":
                        imageSource = '/prmd-parcel-rpt-pdf.png';
                        altText = 'Download PDF Document';
                        break;
                    default:
                        break;
                }
            }
        }

        return <img src={imageSource} alt={altText} />;
    };

    return (
        <div className={styles.document}>
            <a 
                className={styles.iconButton}
                href={`api/documents/${permitDocument.id}`}
                title="Download Document"
                target="_blank"
                rel="noreferrer"
            >
                {renderDownloadIcon()}
            </a>
            <dl>
                {permitDocument?.name?.length > 0 && (
                <>
                    <dt>Document Name: </dt>
                    <dd>
                        <a 
                            className={styles.documentNameLink}
                            href={`api/documents/${permitDocument.id}`}                            
                            target="_blank"
                            rel="noreferrer"
                        >
                            {permitDocument.name}
                        </a>
                    </dd>
                </>
                )}
                {permitDocument?.category?.length > 0 && (
                <>
                    <dt>Category: </dt>
                    <dd>{permitDocument.category}</dd>
                </>
                )}
                {permitDocument?.addresses?.length > 0 && (
                <>
                    <dt>{permitDocument?.addresses?.length > 1 ? 'Addresses: ' : 'Address: '}</dt>
                    <dd>{permitDocument.addresses.map(address => <React.Fragment key={`${permitDocument.id}_${address}`}>{address}<br/></React.Fragment>)}</dd>
                </>
                )}
                {permitDocument?.description?.length > 0 && (
                <>
                    <dt>Description: </dt>
                    <dd>{permitDocument.description}</dd>
                </>
                )}
            </dl>
        </div>
    );
};