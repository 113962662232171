import React from 'react';

import styles from './TimelineArrow.module.scss';

export default function TimelineArrow() {
    return (
        <div className={styles.arrow}>
            <div className={styles.arrowLeft}></div>
            <div className={styles.arrowRight}></div>
        </div>
    );
};