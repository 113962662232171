import React from 'react'

import { faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from './PermitResultPaging.module.scss';

export default function PermitResultsPaging({totalPermits, currentPage, resultsPerPage, resultsPerPageOptions, setCurrentPage, setResultsPerPage, page}) {
    const renderPaging = () => {
        const pagingButtons = [];

        let totalPages = Math.ceil(totalPermits / resultsPerPage);

        let startingPage;

        if (page === "parcelReport") {
            if (totalPages <= 7) {
                startingPage = 1;
            } else {
                if (currentPage + 3 > totalPages) {
                    startingPage = totalPages - 6 > 0 ? totalPages - 6 : 1;
                } else {
                    startingPage = currentPage - 3 > 0 ? currentPage - 3 : 1;
                }
            }
            
            for (let i = startingPage; i < startingPage + 7 && i <= totalPages; i++) {
                pagingButtons.push(
                    <li 
                        key={`permitPaging${i}`}
                        className={(i < currentPage - 2 && i < totalPages - 4) || (i > currentPage + 2 && i > 5) ? styles.desktopOnly : ''}
                    >
                        <button
                            
                            type="button"
                            onClick={() => setCurrentPage(i)}
                            className={styles.pagingButton}
                            aria-label={i === totalPages ? `Last Page, Page ${i}` : `Page ${i}`}
                            aria-current={i === currentPage ? 'page' : null}
                            disabled={currentPage === i}
                        >
                            {i}
                        </button>
                    </li>
                );
            }
        } else {
            if (totalPages <= 10) {
                startingPage = 1;
            } else {
                if (currentPage + 4 > totalPages) {
                    startingPage = totalPages - 9 > 0 ? totalPages - 9 : 1;
                } else {
                    startingPage = currentPage - 5 > 0 ? currentPage - 5 : 1;
                }
            }
            
            for (let i = startingPage; i < startingPage + 10 && i <= totalPages; i++) {
                pagingButtons.push(
                    <li 
                        key={`permitPaging${i}`}
                        className={(i < currentPage - 2 && i < totalPages - 4) || (i > currentPage + 2 && i > 5) ? styles.desktopOnly : ''}
                    >
                        <button
                            
                            type="button"
                            onClick={() => setCurrentPage(i)}
                            className={styles.pagingButton}
                            aria-label={`Page ${i}`}
                            disabled={currentPage === i}
                        >
                            {i}
                        </button>
                    </li>
                );
            }
        }


        return pagingButtons;
    };

    const renderResultsPerPageOptions = () => {
        if (resultsPerPageOptions?.length > 0) {
            return resultsPerPageOptions.map(perPage => {
                return (
                    <option key={perPage} value={perPage}>Show {perPage}</option>
                );
            });
        }
    }

    return (
        <nav 
            aria-label="Permit Detail Pagination"
            className={`${styles.pagingContainer} ${styles[page] ? styles[page] : ''}`}
        >
            {totalPermits > resultsPerPage &&
            <>
            <button
                type="button"
                onClick={() => setCurrentPage(currentPage - 1)}
                className={`${styles.pagingButton} ${styles.prevNextButton}`}
                aria-label="Previous Page"
                disabled={currentPage - 1 <= 0}
            >
                <FontAwesomeIcon className={styles.faSocial} icon={faAngleLeft} />
            </button>
            <ul className={styles.pageButtons}>
                {renderPaging()}
            </ul>
            <button
                type="button"
                onClick={() => setCurrentPage(currentPage + 1)}
                className={`${styles.pagingButton} ${styles.prevNextButton}`}
                aria-label="Next Page"
                disabled={currentPage + 1 > Math.ceil(totalPermits / resultsPerPage)}
            >
                <FontAwesomeIcon className={styles.faSocial} icon={faAngleRight} />
            </button>
            </>
            }
            <div className={styles.resultsPerPageContainer}>
            <select
                className={styles.resultsPerPage}
                value={resultsPerPage}
                onChange={(event) => setResultsPerPage(event.target.value)}
            >
                {renderResultsPerPageOptions()}
                <option value="all">Show All</option>
            </select>
            </div>
        </nav>
    );
};