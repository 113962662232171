import { useEffect, useState } from 'react';

export default function useDropdownVisible() {
    const [visibleComponent, setVisibleComponent] = useState(null);
    const refList = [];        

    const handleClickOutside = event => {
        if (visibleComponent && !refList.some(ref => ref.current.contains(event.target))) {
            setVisibleComponent(null);
        } else if (visibleComponent && visibleComponent.current.contains(event.target) && event.target.nodeName === "BUTTON") {
            setVisibleComponent(null);
        }
    };

    useEffect(() => {
        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };      
    });

    return [ refList, visibleComponent, setVisibleComponent ];
};