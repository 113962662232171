import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import PermitResultsPaging from '../common/PermitResultPaging';

import styles from './PermitSearchResults.module.scss';

export default function PermitSearchResults({address, numResults, results}) {
    const [sortDirection, setSortDirection] = useState('asc');
    const [sortField, setSortField] = useState('parcelNumber');
    const [currentScreenSize, setCurrentScreenSize] = useState(window.innerWidth < 855 ? 'mobile' : 'desktop');
    const [currentPage, setCurrentPage] = useState(1);
    const [resultsPerPage, setResultsPerPage] = useState('all');
    const [allPermits, setAllPermits] = useState();
    const [displayedPermits, setDisplayedPermits] = useState();

    useEffect(() => {
        const sortPermits = permits => {        
            switch (sortField) {
                case 'fileDate':
                    return sortByFileDate(permits);
                default: 
                    return sortPermitsDefault(permits);
            }        
        };
    
        const sortPermitsDefault = permits => {
            if (sortDirection === 'asc') {                
                return permits.sort((x, y) => {
                    if (x[sortField]?.trim() && y[sortField]?.trim()) {
                        if (x[sortField] !== y[sortField]) {
                            return x[sortField] < y[sortField] ? -1 : 1;
                        } else {
                            if (!isNaN(Date.parse(x.fileDate)) && !isNaN(Date.parse(y.fileDate))) {
                                return (new Date(x.fileDate) < new Date(y.fileDate) ? 1 : -1);
                            } else if (!isNaN(Date.parse(y.fileDate))) {
                                return -1;
                            } else {
                                return 1;
                            }            
                        }
                    } else if (x[sortField]?.trim()) {
                        return 1;
                    } else {
                        return -1;
                    }
                });
            } else {
                return permits.sort((x, y) => {
                    if (x[sortField]?.trim() && y[sortField]?.trim()) {
                        if (x[sortField] !== y[sortField]) {
                            return x[sortField] < y[sortField] ? 1 : -1;
                        } else {
                            if (!isNaN(Date.parse(x.fileDate)) && !isNaN(Date.parse(y.fileDate))) {
                                return (new Date(x.fileDate) < new Date(y.fileDate) ? 1 : -1);
                            } else if (!isNaN(Date.parse(y.fileDate))) {
                                return -1;
                            } else {
                                return 1;
                            }
                        }
                    } else if (x[sortField]?.trim()) {
                        return -1;
                    } else {
                        return 1;
                    }
                });
            }
        };
    
        const sortByFileDate = permits => {
            if (sortDirection === 'asc') {
                return permits.sort((x, y) => {
                    if (!isNaN(Date.parse(x.fileDate)) && !isNaN(Date.parse(y.fileDate))) {
                        return (new Date(x.fileDate) > new Date(y.fileDate) ? 1 : -1);
                    } else if (!isNaN(Date.parse(y.fileDate))) {
                        return 1;
                    } else {
                        return -1;
                    }
                });
            } else {
                return permits.sort((x, y) => {
                    if (!isNaN(Date.parse(x.fileDate)) && !isNaN(Date.parse(y.fileDate))) {
                        return (new Date(x.fileDate) < new Date(y.fileDate) ? 1 : -1);
                    } else if (!isNaN(Date.parse(y.fileDate))) {
                        return -1;
                    } else {
                        return 1;
                    }
                });
            }
        };

        if (!allPermits || allPermits.length === 0) {
            setDisplayedPermits(null);
        } else {            
            const sortedPermits = sortPermits(allPermits);
            
            if (resultsPerPage === "all") {
                setDisplayedPermits([...sortedPermits]);
            } else {
                setDisplayedPermits(sortedPermits.slice((currentPage - 1) * resultsPerPage, currentPage * resultsPerPage));
            }
        }

    }, [allPermits, currentPage, sortDirection, sortField, resultsPerPage, setAllPermits, setCurrentPage, setDisplayedPermits]);

    useEffect(() => {
        if (results?.length > 0) {
            let permits = results.reduce((accumulator, parcel) => {
                return [...accumulator, ...parcel.permits];
            }, []);
    
            setAllPermits(permits);        
        } else {
            setAllPermits(null);
        }
    }, [results, setAllPermits, setSortDirection, setSortField]);

    useEffect(() => {
        setCurrentPage(1);
    }, [allPermits, resultsPerPage, sortDirection, sortField]);

    useEffect(() => {
        const handleScreenSizeChange = () => {
            setCurrentScreenSize(window.innerWidth < 855 ? 'mobile' : 'desktop');
        }

        window.addEventListener('resize', handleScreenSizeChange);
        return () => window.removeEventListener('resize', handleScreenSizeChange);
    }, []);

    const renderResults = () => {
        return displayedPermits.map(permit => {
            return (
                <tr key={`${permit.parcelNumber}_${permit.permitNumber}`}>
                    <th scope="col">
                        <Link
                            to={`/parcelreport?APN=${encodeURIComponent(permit.parcelNumber)}&RP=permitSearch`}
                        >
                            {permit.parcelNumber}
                        </Link>
                    </th>
                    <td>
                        <Link 
                            to={`/parcelreport?APN=${encodeURIComponent(permit.parcelNumber)}&PN=${encodeURIComponent(permit.permitNumber)}&RP=permitSearch`}
                        >
                            {permit.permitNumber}
                        </Link>
                    </td>
                    <td>{new Date(permit.fileDate).toLocaleDateString()}</td>
                    <td>{permit.status}</td>
                    <td>{permit.typeAlias}</td>
                    <td>{permit.description}</td>                    
                </tr>
            );
        });
    };

    const setSort = (event, field) => {
        if (event.type === 'click' ||
          (event.type === 'keydown' &&
          ((event.which === 13 || event.keyCode === 13 || event.key === 'Enter') ||
          (event.which === 32 || event.keyCode === 32 || event.key === ' ')))) {
            if (sortField === field) {
                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc'); 
            } else {
                setSortField(field);
                setSortDirection('asc');
            }
        }
    };

    const renderResultsTable = () => {
        return (
            <table className={styles.permitsTable}>               
                <thead>
                    <tr>
                        <th scope="col">
                            <button
                                type="button"
                                onClick={event => setSort(event, 'parcelNumber')}
                            >
                                <div>
                                    <span>Parcel Number</span>
                                    <span>{sortField === 'parcelNumber' ? sortDirection === 'asc' ? '▲' : '▼' : null}</span>
                                </div>
                            </button>
                        </th>
                        <th scope="col">
                            <button
                                type="button"
                                onClick={event => setSort(event, 'permitNumber')}
                            >
                                <div>
                                    <span>Permit Number</span>
                                    <span>{sortField === 'permitNumber' ? sortDirection === 'asc' ? '▲' : '▼' : null}</span>
                                </div>
                            </button>
                        </th>
                        <th scope="col">
                            <button
                                type="button"
                                onClick={event => setSort(event, 'fileDate')}
                            >
                                <div>
                                    <span>Start Date</span>
                                    <span>{sortField === 'fileDate' ? sortDirection === 'asc' ? '▲' : '▼' : null}</span>
                                </div>
                            </button>
                        </th>
                        <th scope="col">
                            <button
                                type="button"
                                onClick={event => setSort(event, 'status')}
                            >
                                <div>
                                    <span>Status</span>
                                    <span>{sortField === 'status' ? sortDirection === 'asc' ? '▲' : '▼' : null}</span>
                                </div>
                            </button>
                        </th>
                        <th scope="col">
                            <button
                                type="button"
                                onClick={event => setSort(event, 'typeAlias')}
                            >
                                <div>
                                    <span>Permit Type</span>
                                    <span>{sortField === 'typeAlias' ? sortDirection === 'asc' ? '▲' : '▼' : null}</span>
                                </div>
                            </button>
                        </th>
                        <th scope="col">
                            <button
                                type="button"
                                onClick={event => setSort(event, 'description')}
                            >
                                <div>
                                    <span>Description</span>
                                    <span>{sortField === 'description' ? sortDirection === 'asc' ? '▲' : '▼' : null}</span>
                                </div>
                            </button>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {displayedPermits && displayedPermits.length > 0 ? renderResults() : null}
                </tbody>
            </table>
        );
    };

    const renderMobileResults = () => {
        if (displayedPermits?.length > 0) {
            return displayedPermits.map(permit => {
                return (
                    <dl 
                        className={styles.mobilePermit}
                        key={`${permit.parcelNumber}_${permit.permitNumber}`}
                    >
                        <dt>Parcel Number: </dt>
                        <dd>
                            <Link 
                                to={`/parcelreport?APN=${encodeURIComponent(permit.parcelNumber)}&RP=permitSearch`}
                            >
                                {permit.parcelNumber}
                            </Link>
                        </dd>
                        <dt>Permit Number: </dt>
                        <dd>
                            <Link 
                                to={`/parcelreport?APN=${encodeURIComponent(permit.parcelNumber)}&RP=permitSearch&PN=${encodeURIComponent(permit.permitNumber)}`}
                            >
                                {permit.permitNumber}
                            </Link>
                        </dd>
                        <dt>Start Date: </dt>
                        <dd>
                            {new Date(permit.fileDate).toLocaleDateString()}
                        </dd>
                        <dt>Status: </dt>
                        <dd>
                            {permit.status}
                        </dd>
                        <dt>Type: </dt>
                        <dd>
                            {permit.typeAlias}
                        </dd>
                        <dt>Description: </dt>
                        <dd>
                            {permit.description}
                        </dd>                        
                    </dl>
                );
            });
        }
    };

    const renderResultsList = () => {
        return (
            <div className={styles.mobileResults}>
                {renderMobileResults()}
            </div>
        );
    };

    return (
        <div className={styles.permitSearchResults}>
            <h2>Permit Search Results</h2>
            <div className={styles.resultsSummary}>
                {results?.length > 0 && 
                <p><span className={styles.label}>Search result as of: </span>{new Date().toLocaleDateString()}</p>
                }
                {address?.length > 0 && 
                    <p><span className={styles.label}>Showing results for address: </span><span className={styles.upperCase}>{address}</span></p>
                }
                {numResults != null &&
                <p><span className={styles.label}>Permits found: </span>{numResults}</p>
                }
            </div>
            {allPermits?.length > 25 &&
            <div className={styles.pageInfo}>
                <div>         
                {resultsPerPage !== 'all' &&
                    <p>Showing {(currentPage - 1) * resultsPerPage + 1} - {currentPage === (Math.ceil(allPermits.length / resultsPerPage)) ? allPermits.length : currentPage * resultsPerPage} of {allPermits.length}</p>           
                }
                </div>
            </div>
            }
            {allPermits?.length > 10 && 
            <PermitResultsPaging
                totalPermits={allPermits?.length}
                currentPage={currentPage}
                resultsPerPage={resultsPerPage}
                setCurrentPage={setCurrentPage}
                setResultsPerPage={setResultsPerPage}
                resultsPerPageOptions={[10, 25, 100]}
            />     
            }
            {(currentScreenSize === 'desktop') ? renderResultsTable() : renderResultsList()}
            {allPermits?.length > 10 && 
            <PermitResultsPaging
                totalPermits={allPermits?.length}
                currentPage={currentPage}
                resultsPerPage={resultsPerPage}
                setCurrentPage={setCurrentPage}
                setResultsPerPage={setResultsPerPage}
                resultsPerPageOptions={[10, 25, 100]}
            />            
            }
        </div>
    );
};