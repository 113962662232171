import React from 'react';

import styles from './Disclaimer.module.scss';

export default function Disclaimer() {
    return (
        <div className={styles.disclaimer}>
            <h2>Important Disclaimers</h2>
            <ul>
                <li>All reasonable effort has been made to ensure the accuracy of the data provided. However, some of the data may be out of date or inaccurate.</li>
                <li>The County of Sonoma assumes no responsibility arising from the use of this information. This information and associated data are provided without warranty of any kind, either expressed or implied, including but not limited to, the implied warranties of merchantability and fitness for a particular purpose. Do not make any business or financial decisions based on this data before validating the data with Permit Sonoma.</li>
                <li>The Permit History provided may not include all records for this property. Further investigation may be required to obtain a more complete history due to: address changes, parcel mergers, splits, retirements, etc.</li>
                <li>Date(s) listed in this report reflect the earliest known date(s) associated with each record. The date(s) listed are not associated with the current status.</li>
                <li>Permit Status Definitions have been modified through time to better identify milestones during the lifespan of a specific record type. Many historical records reflect statuses which are no longer in use.</li>
                <li>Although every reasonable effort is made to provide complete and accurate information on this website, visitors are advised to contact appropriate Permit Sonoma staff before making project decisions or property purchase/lease decisions. This may involve contacting more than one section within Permit Sonoma (Building Inspection, Plan Check, Planning/Zoning, Well & Septic, etc.) since each section implements specific codes or ordinances which may affect your project.</li>
            </ul>
        </div>
    );
};
